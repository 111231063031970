import {
  ApolloClient,
  InMemoryCache,
  gql,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from 'subscriptions-transport-ws';

let GRAPHQL_ENDPOINT_WS = "wss://flex2.dev.tonlabs.io/graphql";

function setEndpoint(endpoint) {
  //endpoint in format flex4.dev.tonlabs.io
  GRAPHQL_ENDPOINT_WS = `wss://${endpoint}/graphql`;
}

function getSubscriptionClient() {
  if (window.subscriptionClient) {
    return window.subscriptionClient;
  }

  const wsClient = new SubscriptionClient(GRAPHQL_ENDPOINT_WS, {
    reconnect: true,
  });
  wsClient.onConnected(() => console.log("websocket connected!!"));
  wsClient.onDisconnected(() => {
    console.log("websocket disconnected!!");
  });
  wsClient.onReconnected(() => console.log("websocket reconnected!!"));

  const link = new WebSocketLink(wsClient);

  window.subscriptionClient = new ApolloClient({
    link,
    cache: new InMemoryCache()
  });
  return window.subscriptionClient;
}

function subscribeUserTrades(userId) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      flexUserTrades(
        userId: "${userId}"
      ) {
        side
        price
        amount
        time
        pair {ticker address priceScale minAmount minMove}
        cursor
        fees
        feesToken {ticker}
        liquidity
      }
    }`,
    variables: {}
  });
}

function subscribeUserWallets(userId, flexClient) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      flexWallets(
        userId: "${userId}"
        clientAddress: "${flexClient}"
      ) {
        address
        cursor
        balanceInOrders
        availableBalance
        nativeCurrencyBalance
        totalBalance
        token {ticker decimals}
      }
    }`,
    variables: {}
  });
}

function subscribeUserOrders(userId) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      flexUserOrders(
        userId: "${userId}"
      ) {
        orderId
        pair {ticker address priceScale minAmount minMove}
        price
        priceNum
        side
        amountLeft
        finishTime
      }
    }`,
    variables: {}
  });
}

function subscribeOrderbook(address) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      flexMarketOrderBook(
        pairAddress: "${address}"
      ) {
        bids {
          price
          amount
        }
        asks {
          price
          amount
        }
      }
    }`,
    variables: {}
  });
}

function subscribeBars(address, interval) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      flexMarketPriceHistory(
        pairAddress: "${address}"
        resolution: ${interval*60}
      ) {
        open close low high time volume
      }
    }`,
    variables: {}
  });
}

function subscribeRecentTrades(address) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      flexMarketRecentTrades(
        pairAddress: "${address}"
      ) {
        price amount cursor time side amountUnits
      }
    }`,
    variables: {}
  });
}

function subscribeBalance(address) {
  return getSubscriptionClient().subscribe({
    query: gql`
    subscription {
      accounts(filter: {id:{eq: "${address}"}})
      {balance}
    }`,
    variables: {}
  });
}

const subscriptionsAPI = {
  getSubscriptionClient,
  subscribeOrderbook,
  subscribeBars,
  subscribeRecentTrades,
  subscribeUserOrders,
  subscribeUserWallets,
  subscribeUserTrades,
  subscribeBalance,
  setEndpoint,
};

export default subscriptionsAPI;
