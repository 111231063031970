// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Table, Fade } from "react-bootstrap";
import styles from "./trades/Trades.module.scss";
import classnames from "classnames/bind";
import MetaDecorator from "../utils/metaDecorator";
import { useDashboard } from "../controllers/useDashboard";
import { useTheme } from "../controllers/useTheme";
import { Orderbook } from "./orderbook";
import { Form } from "./form";
import { Trades } from "./trades";
//import { FlexChartContainer } from '../flex_charts/chart';
import CursorTooltip from '../components/cursor-tooltip';
import {ConnectButton} from '../components/button';
import { Debots } from "../client/client";
import DebotClient from "../client/client";
import Auth from "../client/poochy";
import { ENDPOINT, chartsUrl } from "../client/client";

import {
  PairInfo,
 } from "../interfaces";

import { Stats, Panel, Button, Icon, Skeleton, StatsSkeleton, Loader, Toast } from "../components";
import {getDecimalsCount, formatDate, formatNumber, isExpired} from "./utils";

const cn = classnames.bind(styles);

const showGasInAssets = false;

export interface IOrderbookProps {
  selectedPair: PairInfo | undefined;
}

const Main = ({
  showModal,
  handleShow,
  setModalUrl,
  handleConnectSurf,
  ...props
}: {
  showModal: boolean,
  handleShow: (value?: any) => void,
  setModalUrl: (url: string, closableOnUrl?: boolean)=> void,
  handleConnectSurf: () => void,
}) => {
  const [formMode, setFormModal] = useState<string>("limit");
  const [canceledOrders, setCanceledOrders] = useState<any>([]);
  const chartTheme = useTheme();

  const {
    selectedPair,
    pairInfo,
    authorized,
    selectedOrderBookRow,
    userOpenOrders,
    userWallets,
    userTrades,
    userGas,

    setExchangeMode,
    exchangeMode,

    trade,
    tradeStatus,
    setTradeStatus,
    tradeLoading,
    cancelOrder,
  } = useDashboard();

    const pairInfoNumber = pairInfo ? {
      price: parseFloat(pairInfo.price),
      h24open: parseFloat(pairInfo.h24open),
      h24high: parseFloat(pairInfo.h24high),
      h24low: parseFloat(pairInfo.h24low),
      h24volume: parseFloat(pairInfo.h24volume)
    } : null;

    const pairStatistics = pairInfo ? [
      {
        title: "Daily change",
        value: formatNumber(pairInfoNumber.price - pairInfoNumber.h24open, selectedPair ? getDecimalsCount(selectedPair.minMove/selectedPair.priceScale) : 0),
        delta: {
          value: (pairInfoNumber.price - pairInfoNumber.h24open) * 100 / pairInfoNumber.h24open,
          units: "%"
        }
      },
      {
        title: "Price",
        value: formatNumber(pairInfoNumber.price, selectedPair ? getDecimalsCount(selectedPair.minMove/selectedPair.priceScale) : 0)
      },
      {
        title: "High, 24h",
        value: formatNumber(pairInfoNumber.h24high, selectedPair ? getDecimalsCount(selectedPair.minMove/selectedPair.priceScale) : 0)
      },
      {
        title: "Low, 24h",
        value: formatNumber(pairInfoNumber.h24low, selectedPair ? getDecimalsCount(selectedPair.minMove/selectedPair.priceScale) : 0)
      },
      {
        title: "Volume",
        value: formatNumber(pairInfoNumber.h24volume, selectedPair ? getDecimalsCount(selectedPair.minAmount) : 0)
      }
    ] : [];

    const hasGas = !isNaN(userGas);
    const hasInnerWallets = userWallets && userWallets.length > 0;
    const innerWalletsGas = hasInnerWallets ? userWallets.reduce((sum, w) => sum + w.nativeCurrencyBalance, 0) : 0;

    const totalGas = hasGas ? userGas + innerWalletsGas : undefined;
    const gasThreshold = 50 + (hasInnerWallets ? userWallets.length * 15 : 0);
//  threshold = (50 + N*15) EVER, where N - number of user assets on Flex (6 assets in Funds on the screenshot above)

    return (
      <>
      <MetaDecorator
        title="Flash Exchange"
        description=""
        keywords=""
      />
      <Toast
        header={tradeStatus && (tradeStatus.status === '0' || (tradeStatus.status === undefined && tradeStatus.executedAmount)
          ? <span className="green">{tradeStatus.additionalInfo === 'cancelOrder' ? 'Order canceled!' : tradeStatus.additionalInfo === 'market' ? 'Market order executed!' : 'Order placed!'}</span>
          : <span className="red">Order placement error</span>)
        }
        show={Boolean(tradeStatus)}
        onClose={() => setTradeStatus(undefined)}
      >
        {tradeStatus ? (() => {
          if (tradeStatus.status === undefined && tradeStatus.executedAmount) {
            //return `Executed amount: ${tradeStatus.executedAmount}`;
            return `Market order has been executed successfully`;
          }
          switch (tradeStatus.status) {
            case "0":
              if (tradeStatus.sdkError === "0" && tradeStatus.exitCode === "0") {
                return <>{tradeStatus.additionalInfo === 'cancelOrder' ? 'Your Open Orders will update in seconds' : 'It will appear in your Open Orders in seconds'}</>
              } else {
                return <>Unknown error. Error code: {JSON.stringify(tradeStatus)}</>
              }

            case "2":
              return <>GraphQL request error</>

            case "24":
              return <>POST order cannot be placed at the given price</>

            case "25":
              return <>IOC order cannot be filled at the given price</>

            case "3":
              return <>GET method error</>

            case "5":
              return <>No sender wallet</>

            case "6":
              return <>Unknown wrapper type</>

            case "7":
              return <>No receiver wallet</>

            case "4":
                switch (tradeStatus.exitCode) {
                  case "100":
                    return <>Wrong signature (relink account)</>

                  case "101":
                    return <>Insufficient balance</>

                  case "109":
                    return <>Gas balance is too low</>

                  case "114":
                    return <>Not enough EVERs to process</>

                  case "119":
                    return <>Your wallet is not allowed to work with that flex. Rebind wallet</>

                  default:
                    return <>Unknown error. Error code: {JSON.stringify(tradeStatus)}</>
                }

            default:
              return <>Unknown error. Error code: {JSON.stringify(tradeStatus)}</>
          }
        })() : null}
        {/* (tradeStatus.status === '0' ? "It will appear in your Open Orders in seconds" : JSON.stringify(tradeStatus))} */}
      </Toast>
      <Container fluid>
        <Row>
          <Col xs={{ span: 12, order: 0 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <div className="market-indicators">
              <div className={cn("pair headers headers-small", {loading: !selectedPair})}>
                {selectedPair
                ? <>
                    <div className="icons">
                      <div className={`icon-${selectedPair.major.toLowerCase()}`}></div>
                      <div className={`icon-${selectedPair.minor.toLowerCase()}`}></div>
                    </div>
                    <div>{selectedPair.ticker.split('/')[0]}<em>/</em>{selectedPair.ticker.split('/')[1]}</div>
                  </>
                : <>
                    <div className="icons">
                      <div></div>
                      <div></div>
                    </div>
                    <div className="skeleton-pair"><Skeleton size="large" variant="primary"/><Skeleton size="large" variant="primary"/></div>
                  </>
                }
              </div>
              <div className="indicators w-100" style={{marginRight: 0}}>
                {selectedPair && pairStatistics.length && pairInfo
                ? <>
                  {pairStatistics.map((item, index) => <Stats
                    key={index}
                    title={item.title}
                    value={item.value}
                    delta={item.delta}
                  />)}
                  </>
                : <>
                    <StatsSkeleton className={"green"} />
                    {[0,1].map((item, index) => <StatsSkeleton key={index} />)}
                  </>
                }

                {authorized && hasGas
                  && <div className={cn("gas-box")} style={{marginLeft: 'auto', marginRight: 0}}>
                  <Stats
                    title="Top up gas"
                    valueComponent={<Button
                        className={cn({"btn-buy": totalGas >= gasThreshold}, {"btn-sell": totalGas < gasThreshold})}
                        onClick={()=>handleShow('deposit-gas')}
                      >
                        {formatNumber(totalGas, 2)}
                      </Button>}
                  /></div>}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }} xxl={{ span: 3, order: 1 }} className={"column"}>
            <Form
              selectedPair={selectedPair}
              authorized={Boolean(authorized)}
              selectedOrderBookRow={selectedOrderBookRow}
              setExchangeMode={setExchangeMode}
              exchangeMode={exchangeMode}
              handleShow={handleShow}
              handleConnectSurf={handleConnectSurf}
              trade={trade}
              tradeLoading={tradeLoading}
              walletsInfo={selectedPair && userWallets && userWallets.filter(w => selectedPair.ticker.includes(w.ticker))}
            />

            <Panel
              header=""
            >
              <div className="hstack gap-2 mb-3 assets-box">
                <div className="head-narrow head-narrow-note">Assets</div>
                {authorized && <div className={cn("assets-box")} style={{marginLeft: 'auto'}}>
                  <Button
                    className={cn("btn-assets")}
                    onClick={()=>{
                      handleShow('deposit');
                    }}
                  >
                    {'Deposit'}
                  </Button>
                </div>}
                {authorized && <div className={cn("assets-box")}>
                  <Button
                    className={cn("btn-assets")}
                    onClick={()=>{
                      handleShow('withdraw');
                    }}
                  >
                    {'Withdraw'}
                  </Button>
                </div>}
              </div>
              <div className={cn("scrollable-table-container")}>
              <Table>
                <thead>
                  <tr>
                    <th className="paragraph paragraph-footnote">Asset</th>
                    <th className="paragraph paragraph-footnote right">Available</th>
                  </tr>
                </thead>
                <tbody className="mono-narrow mono-narrow-note">
                  {selectedPair && userWallets.filter(w => selectedPair.ticker.includes(w.ticker)).map((wallet, index) => {
                    return <tr key={index}>
                      <td valign="top">{wallet.ticker}</td>
                      <td className="right" valign="top">
                        {formatNumber(wallet.availableBalance)}
                      </td>
                    </tr>
                  })}
                  {authorized && userGas && !isNaN(userGas) && showGasInAssets && <tr>
                    <td valign="top">
                      <CursorTooltip content={'We recommend topping up your Gas balance by 100 EVER'}>
                        <span>{'Gas'}</span>
                      </CursorTooltip>
                    </td>
                    <td className="right" valign="top" style={userGas < 50 ? {color: "red"} : undefined}>
                      {formatNumber(userGas, 9)}
                    </td>
                    <td className="right" valign="top">
                      <div className={cn("trade-table-button")}
                        onClick={()=>{
                          setModalUrl(`https://uri.ever.surf/transfer/${authorized?.userIdAddress}?net=${Debots.get('auth')!.alias}`, true);
                          handleShow();
                        }}
                      >
                        Deposit
                      </div>
                    </td>
                  </tr>}
                </tbody>
              </Table>
              </div>
              <ConnectButton authorized={authorized} onClick={handleConnectSurf} />
            </Panel>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} xxl={{ span: 6, order: 2 }} className={"column"}>
            <Panel
              className={cn("trading-view")}
            >
              {selectedPair ?
                <iframe
                  src={`${chartsUrl}?theme=${chartTheme}&endpoint=${ENDPOINT}&address=${selectedPair.address}&ticker=${selectedPair.ticker}`}
                  height={520}
                />
              /*<FlexChartContainer
                pair={{
                  address: selectedPair.address,
                  ticker: selectedPair.ticker
                }}
                height={520}
                theme={chartTheme}
              />*/ : <div className="loader-wrapper"><Loader /></div>}
            </Panel>

            <Tab.Container
                defaultActiveKey={"open-orders"}
                onSelect={(activeKey) => {
                  setFormModal(String(activeKey).toLowerCase());
                }}
                // transition={Fade}
              >
            <Panel
              header={<Nav variant="pills" className={cn("head-narrow head-narrow-note", "nav-pills")} style={{flexWrap: 'nowrap'}}>
                  <Nav.Item>
                    <Nav.Link className={cn("nav-link-pill")} eventKey="open-orders">Open orders<sup>{userOpenOrders.length ? userOpenOrders.length : ""}</sup></Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link disabled={true} className={cn("nav-link-pill")} eventKey="order-history">Order history</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link className={cn("nav-link-pill")} eventKey="trade-history">Trade history</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className={cn("nav-link-pill")} eventKey="funds">Funds</Nav.Link>
                  </Nav.Item>
                </Nav>}
              >
                <Tab.Content>
                  <Tab.Pane eventKey="open-orders">
                    <div className={cn("scrollable-table-container")}>
                    <Table>
                      <thead>
                        <tr>
                          <th className="paragraph paragraph-footnote">Market</th>
                          <th className="paragraph paragraph-footnote">Side</th>
                          <th className="paragraph paragraph-footnote right">Price</th>
                          <th className="paragraph paragraph-footnote right">Amount</th>
                          <th className="paragraph paragraph-footnote right">Expire</th>
                          <th className="paragraph paragraph-footnote"></th>
                        </tr>
                      </thead>
                      <tbody className={cn("mono-narrow mono-narrow-note", "trades")}>
                        {userOpenOrders.map((order, index) => {
                          const [from, to] = order.ticker.split("/");
                          const decimalsCountPrice = getDecimalsCount(1/order.priceScale);
                          const decimalsCountAmount = getDecimalsCount(order.minAmount);
                          console.log(`order.finishTime=${order.finishTime}`);

                          return <tr key={index}>
                            <td>{from}<em>/</em>{to}</td>
                            <td className={cn({"green": order.side.toLowerCase() === "buy"}, {"red": order.side.toLowerCase() === "sell"})}>
                              {order.side[0].toUpperCase() + order.side.toLowerCase().substring(1)}
                            </td>
                            <td className="right">{formatNumber(order.price,  decimalsCountPrice)}</td>
                            <td className="right">{formatNumber(order.amount, decimalsCountAmount)}</td>
                            <td className={cn("right", {"faded": !isExpired(order.finishTime)}, {"red": isExpired(order.finishTime)})}>
                              {formatDate(order.finishTime)}
                            </td>
                            <td className="right">
                              <div
                                className={cn("trade-table-button", {disabled: canceledOrders.includes(order.orderId)})}
                                onClick={()=>{
                                  setCanceledOrders(canceledOrders.concat(order.orderId));
                                  cancelOrder(
                                    order.pairAddress,
                                    order.side === 'SELL',
                                    order.priceNum,
                                    order.orderId,
                                    (tradeStatus) => {
                                      if (tradeStatus.status === '0') return;
                                      //remove order from canceled, as canceling didn't success
                                      setCanceledOrders(canceledOrders.filter(item => item.orderId !== order.orderId));
                                    }
                                  );
                                }}>
                                Cancel
                              </div>
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="trade-history">
                    <div className={cn("scrollable-table-container")}>
                    <Table>
                      <thead>
                        <tr>
                          <th className="paragraph paragraph-footnote">Market</th>
                          <th className="paragraph paragraph-footnote">Side</th>
                          <th className="paragraph paragraph-footnote right">Price</th>
                          <th className="paragraph paragraph-footnote right">Amount</th>
                          <th className="paragraph paragraph-footnote right">Liquidity</th>
                          <th className="paragraph paragraph-footnote right">Fees</th>
                          <th className="paragraph paragraph-footnote right"></th>
                          <th className="paragraph paragraph-footnote right">Time</th>
                        </tr>
                      </thead>
                      <tbody className={cn("mono-narrow mono-narrow-note", "trades")}>
                        {userTrades.sort((trade1, trade2) => trade2.time - trade1.time).map((trade, index) => {
                          const [from, to] = trade.ticker.split("/");
                          const decimalsCountPrice = getDecimalsCount(1/trade.priceScale);
                          const decimalsCountAmount = getDecimalsCount(trade.minAmount);
                          return <tr key={index}>
                            <td>{from}<em>/</em>{to}</td>
                            <td className={cn({"green": trade.side.toLowerCase() === "buy"}, {"red": trade.side.toLowerCase() === "sell"})}>{trade.side[0].toUpperCase() + trade.side.slice(1).toLowerCase()}</td>
                            <td className="right">{formatNumber(trade.price,  decimalsCountPrice)}</td>
                            <td className="right">{formatNumber(trade.amount, decimalsCountAmount)}</td>
                            <td className="right">{trade.liquidity}</td>
                            <td className="right">{trade.fees}</td>
                            <td className="left">{trade.feesToken}</td>
                            <td className="right faded">{formatDate(trade.time)}</td>
                          </tr>
                        })}
                      </tbody>
                    </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="funds">
                    <div className={cn("scrollable-table-container")}>
                    <Table>
                      <thead>
                        <tr>
                          <th className="paragraph paragraph-footnote">Asset</th>
                          <th className="paragraph paragraph-footnote right">Total</th>
                          <th className="paragraph paragraph-footnote right">In Orders</th>
                          <th className="paragraph paragraph-footnote right">Available</th>
                          {false && <th className="paragraph paragraph-footnote"></th>}
                        </tr>
                      </thead>
                      <tbody className={cn("mono-narrow mono-narrow-note", "trades")}>
                        {userWallets.map((fund, index) => {
                          return <tr key={index}>
                            <td>{fund.ticker}</td>
                            <td className="right">{formatNumber(fund.totalBalance)}</td>
                            <td className="right faded">{formatNumber(fund.balanceInOrders)}</td>
                            <td className="right">{formatNumber(fund.availableBalance)}</td>
                            {false && <td className="right">
                              <div className={cn("trade-table-button")}
                                onClick={()=>{
                                  Auth.getInvokeUnwrapMessage(fund.address).then(message => {
                                    setModalUrl(`https://uri.ever.surf/debot/${Debots.get('auth')!.address}?message=${message.message}&net=${Debots.get('auth')!.alias}`, true)
                                    handleShow();
                                  })
                                }}
                              >
                                Withdraw
                              </div>
                            </td>}
                          </tr>
                        })}
                        {showGasInAssets && <tr>
                          <td>{`Gas`}</td>
                          <td className="right">
                            {userGas && !isNaN(userGas) && userWallets && formatNumber(userWallets.reduce((sum, w) => sum + w.nativeCurrencyBalance, userGas), 9)}
                          </td>
                          <td className="right">
                            {userWallets && formatNumber(userWallets.reduce((sum, w) => sum + w.nativeCurrencyBalance, 0), 9)}
                          </td>
                          <td className="right" style={userGas && userGas < 50 ? {color: "red"} : undefined}>
                            {userGas && !isNaN(userGas) && formatNumber(userGas, 9)}
                          </td>
                          <td className="right">
                            {false &&
                            <div className={cn("trade-table-button")}
                              onClick={()=>{}}
                            >
                              Recover
                            </div>
                            }
                          </td>
                        </tr>
                        }
                      </tbody>
                    </Table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <ConnectButton authorized={authorized} onClick={handleConnectSurf} />
              </Panel>
            </Tab.Container>
          </Col>
          <Col xs={{ span: 12, order: 3 }} md={{ span: 6, order: 3 }} xxl={{ span: 3, order: 3 }} className={"column"}>
            <Orderbook selectedPair={selectedPair} />
            <Trades selectedPair={selectedPair} />
          </Col>
        </Row>
      </Container>
      </>
    );
  };

export default Main;
