export const authabi = `{
	"ABI version": 2,
	"version": "2.3",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetInvokeUnwrapMessage",
			"inputs": [
				{"name":"message","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetFlexClientAndUserId",
			"inputs": [
				{"name":"flexClient","type":"optional(address)"},
				{"name":"userId","type":"optional(uint256)"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetUserIdAddress",
			"inputs": [
				{"name":"addr","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetInvokeAuthMessage",
			"inputs": [
				{"name":"message","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetInvokeUnwrapMessage",
			"inputs": [
				{"name":"message","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onIsUpdateNeeded",
			"inputs": [
				{"name":"res","type":"bool"}
			],
			"outputs": [
			]
		},
		{
			"name": "constructor",
			"inputs": [
			],
			"outputs": [
			]
		}
	],
	"data": [
	],
	"events": [
	],
	"fields": [
		{"name":"_pubkey","type":"uint256"},
		{"name":"_timestamp","type":"uint64"},
		{"name":"_constructorFlag","type":"bool"}
	]
}`;

export const tradeabi = `{
    "ABI version": 2,
    "version": "2.2",
    "header": ["time"],
    "functions": [
        {
            "name": "onTrade",
            "inputs": [
                {"name":"status","type":"uint8"},
                {"name":"sdkError","type":"uint32"},
                {"name":"exitCode","type":"uint32"}
            ],
            "outputs": [
            ]
        },
				{
					"name": "onMarketTrade",
					"inputs": [
						{"name":"executedAmount","type":"uint128"}
					],
					"outputs": [
					]
				},
				{
						"name": "onCancelOrder",
						"inputs": [
								{"name":"status","type":"uint8"},
								{"name":"sdkError","type":"uint32"},
								{"name":"exitCode","type":"uint32"}
						],
						"outputs": [
						]
				},
        {
            "name": "onSBox",
            "inputs": [
                {"name":"result","type":"uint32"},
                {"name":"key","type":"uint256"}
            ],
            "outputs": [
            ]
        },
        {
            "name": "constructor",
            "inputs": [
            ],
            "outputs": [
            ]
        }
    ],
    "data": [
    ],
    "events": [
    ],
    "fields": [
        {"name":"_pubkey","type":"uint256"},
        {"name":"_timestamp","type":"uint64"},
        {"name":"_constructorFlag","type":"bool"}
    ]
}`;

export const testSignAbi = `{
    "ABI version": 2,
    "version": "2.3",
    "header": ["time"],
    "functions": [
        {
            "name": "onSend2Giver",
            "inputs": [
                {"name":"status","type":"uint8"},
                {"name":"sdkError","type":"uint32"},
                {"name":"exitCode","type":"uint32"},
                {"name":"pubkey","type":"uint256"}
            ],
            "outputs": [
            ]
        }
    ],
    "data": [
    ],
    "events": [
    ],
    "fields": [
    ]
}`;


export const configDebotAbi = `{
	"ABI version": 2,
	"version": "2.3",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetAddress",
			"inputs": [
				{"name":"res","type":"optional(address)"}
			],
			"outputs": [
			]
		}
	]
}`;

export const authExtAbi = `{
	"ABI version": 2,
	"version": "2.3",
	"header": ["time"],
	"functions": [
		{
			"name": "onAuth",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"flexClient","type":"address"},
				{"name":"userId","type":"uint256"},
				{"name":"userIdAddress","type":"address"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onDeployUserId",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"}
			],
			"outputs": [
			]
		},
		{
			"name": "onDeployFlexClientSign",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onDeployFlexClient",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onReBindWallets",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"}
			],
			"outputs": [
			]
		}
	],
	"data": [
	],
	"events": [
	],
	"fields": [
	]
}`;

export const assetsAbi = `{
	"ABI version": 2,
	"version": "2.3",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetExtWallets",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"components":[{"name":"addr","type":"string"},{"name":"balance","type":"string"},{"name":"decimals","type":"uint32"},{"name":"symbol","type":"string"},{"name":"wrapperWallet","type":"string"}],"name":"extWallets","type":"tuple[]"}
			],
			"outputs": [
			]
		},
		{
			"name": "onGetIntWallets",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"components":[{"name":"addr","type":"string"},{"name":"balance","type":"string"},{"name":"decimals","type":"uint32"},{"name":"symbol","type":"string"}],"name":"intWallets","type":"tuple[]"}
			],
			"outputs": [
			]
		},
		{
			"name": "onDeposit",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"}
			],
			"outputs": [
			]
		},
		{
			"name": "onWithdraw",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"}
			],
			"outputs": [
			]
		}
	],
	"data": [
	],
	"events": [
	],
	"fields": [
	]
}`;

export const recoverAbi = `{
	"ABI version": 2,
	"version": "2.3",
	"header": ["time"],
	"functions": [
		{
			"name": "onRecoverGas",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onCancelAllOrders",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onBurnWallets",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onBurnEmptyWallets",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"},
				{"name":"cache","type":"cell"}
			],
			"outputs": [
			]
		},
		{
			"name": "onBurnUserID",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"}
			],
			"outputs": [
			]
		},
		{
			"name": "onWithdrawFromFlexClient",
			"inputs": [
				{"name":"status","type":"uint8"},
				{"name":"sdkError","type":"uint32"},
				{"name":"exitCode","type":"uint32"}
			],
			"outputs": [
			]
		}
	],
	"data": [
	],
	"events": [
	],
	"fields": [
	]
}`;

const abis = {
	authabi,
	tradeabi,
	testSignAbi,
	configDebotAbi,
	authExtAbi,
	assetsAbi,
	recoverAbi,
};

export default abis;
