import { useState, useEffect } from "react";

export const useTheme = () => {
  const [theme, setTheme] = useState<string>('Dark');

  useEffect(() => {
    const checkTheme = (e: any) => {
      if (e.currentTarget.checked === true) {
        localStorage.removeItem('flexThemeLight');
        setTheme('Dark');
      } else {
        localStorage.setItem('flexThemeLight', 'true');
        setTheme('Light');
      }
    }

    const themeSwitch: any = document.getElementById('theme-switch');
    if (themeSwitch) {
      setTheme(themeSwitch.checked ? 'Dark' : 'Light');
      themeSwitch.addEventListener('change', checkTheme);
    }

    return () => {
      themeSwitch && themeSwitch.removeEventListener('change', checkTheme);
    }
  }, []);

  return theme;
};
