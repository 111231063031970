import Subscriptions from './subscriptions';

let GRAPHQL_ENDPOINT = "https://flex2.dev.tonlabs.io/graphql";
const SUPER_ROOT = "0:ed1741f19f7c2f870e96bca16c45283721f023235dc6896c765407e9127bb073";

function setEndpoint(endpoint) {
  //endpoint in format flex4.dev.tonlabs.io
  GRAPHQL_ENDPOINT = `https://${endpoint}/graphql`;
  Subscriptions.setEndpoint(endpoint);
}

async function unsubscribe(subscribeUID) {
  //console.log(`window.subscriptions[${subscribeUID}] =>`, window.subscriptions[subscribeUID]);
  if (
    !window.subscriptions ||
    !window.subscriptions[subscribeUID]
  ) return;
  await window.subscriptions[subscribeUID].unsubscribe();
}


async function subscribeBalance(address, subscribeUID, callback) {
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeBalance(address)
  .subscribe({
    next(data) {
      //console.log(`got new subscribeBalance for ${address} =>`, data);
      const accounts = data.data.accounts;
      const balance = accounts ? accounts.balance*1.0/1000000000 : undefined;
      callback(balance);
    }
  });
}

async function subscribeUserOrders(userId, subscribeUID, onRealtimeCallback) {
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeUserOrders(userId)
  .subscribe({
    next(data) {
      //console.log(`got new subscribeUserOrders for ${userId} =>`, data);
      const newUserOrders = data.data.flexUserOrders;
      onRealtimeCallback(newUserOrders);
    }
  });
}

async function subscribeUserWallets(userId, flexClient, subscribeUID, onRealtimeCallback) {
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeUserWallets(userId, flexClient)
  .subscribe({
    next(data) {
      //console.log(`got new subscribeUserWallets for ${userId} =>`, data);
      const flexWallets = data.data.flexWallets;
      onRealtimeCallback(flexWallets);
    }
  });
}

async function subscribeUserTrades(userId, subscribeUID, onRealtimeCallback) {
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeUserTrades(userId)
  .subscribe({
    next(data) {
      //console.log(`got new subscribeUserTrades for ${userId} =>`, data);
      const flexUserTrades = data.data.flexUserTrades;
      onRealtimeCallback(flexUserTrades.map(trade => ({...trade, id: trade.cursor})));
    }
  });
}

async function subscribeRecentTrades(address, subscribeUID, onRecentTradesCallback) {
  const pair = await flexPairByTicker('', address);
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeRecentTrades(address)
  .subscribe({
    next(data) {
      const recentTrades = data.data.flexMarketRecentTrades;
      //console.log(`got new recentTrades for ${address} =>`, recentTrades);
      onRecentTradesCallback(recentTrades.map(trade => ({...trade, volume: trade.amount, id: trade.cursor})), pair);
    }
  });
}

async function subscribeOrderbook(address, subscribeUID, onRealtimeCallback) {
  const pair = await flexPairByTicker('', address);
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeOrderbook(address)
  .subscribe({
    next(data) {
      const newOrderBook = data.data.flexMarketOrderBook;
      //console.log(`got new orderbook for ${address} =>`, newOrderBook);
      onRealtimeCallback(newOrderBook, pair);
    }
  });
}

async function subscribeBars(ticker, interval, onRealtimeCallback, subscribeUID) {
  const pair = await flexPairByTicker(ticker);
  if (!pair) return null;
  // /0:3dfc69a030b13cb6363340dd7c5d1511ed3035e4454c66bb473544d4d032ab5a
  if (!window.subscriptions) window.subscriptions = {};
  window.subscriptions[subscribeUID] = Subscriptions.subscribeBars(pair.address, interval)
  .subscribe({
    next(data) {
      const newBars = data.data.flexMarketPriceHistory;
      //sort newBars by time:
      newBars.sort((p1,p2) => {
          if (p1.time < p2.time) return -1;
          if (p1.time > p2.time) return 1;
          return 0;
      });
      //console.log(`got new bars for ${pair.address} =>`, newBars);
      const lastBar = window.subscriptions && window.subscriptions[`${ticker}-${interval}`] ?
                      window.subscriptions[`${ticker}-${interval}`].lastBar : null;

      newBars.forEach(bar => {
        bar.time *= 1000;
        if (
          (lastBar && lastBar.time <= bar.time)
          ||
          !lastBar
        )
        onRealtimeCallback(bar);
      });
    }
  });
}

function getSymbolName(pair) {
  return `${pair.ticker} ${pair.address.substr(0,4)}`;
}

function getSymbolDescription(pair) {
  return `${pair.ticker}`;//` ${pair.address}`;
}

async function flexQuery(query) {
    //console.log(`Query => ${query}`);
    return fetch(GRAPHQL_ENDPOINT, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(
          {
            query,
          }
        ),
    })
    .then(response => {
        return response.json();
    })
    .then((data) => {
        return data.data;
    });
}

async function flexVersion() {
    return flexQuery(`query {flex {version}}`)
        .then((data) => {
            if (!data || !data.flex) throw new Error(`Couldn't get flex version`);
            return data.flex.version;
        }).catch((error) => {
            console.error(`flexVersion:error`, error);
            return '';
        });
}

async function flexTime() {
    return flexQuery("query { info {time}}")
        .then((data) => {
            if (!data || !data.info) throw new Error(`Couldn't get flex server time`);
            return data.info.time;
        }).catch((error) => {
            console.error(`flexTime:error`, error);
            return 0;
        });
}

async function flexBalance(address) {
    return flexQuery(`query {
          accounts(filter: {id:{eq: "${address}"}})
          {id balance}
        }`)
        .then((data) => {
            if (!data || !data.accounts) throw new Error(`Couldn't get balance for ${address}`);
            return data.accounts.length ? data.accounts[0].balance*1.0/1000000000 : undefined;
        }).catch((error) => {
            console.error(`flexBalance:error`, error);
            return undefined;
        });
}

async function flexPairs() {
    return flexQuery("query{"+
              `flex{`+
              "  pairs{"+
              "    address"+
              "    ticker"+
              "    major {ticker decimals}"+
              "    minor {ticker decimals}"+
              "    minMove"+
              "    priceScale"+
              "    minAmount"+
              "  }"+
              "}"+
              "}")
        .then((data) => {
            //console.log('flexPairs:data', data);
            if (!data || !data.flex) throw new Error(`Couldn't get flex pairs`);
            return data.flex.pairs;
        }).catch((error) => {
            console.error(`flexPairs:error`, error);
            return [];
        });
}

async function flexPairByTicker(ticker = '', address = '') {
    const pairs = await flexPairs();
    if (!pairs) return null;
    const pair = pairs.find(
      pair =>
        (pair.address === address) ||
        (ticker.length && pair.address.startsWith(ticker.substr(ticker.length - 4)))
    );
    return pair || null;
}

async function flexOrderBook(address, limit = 0) {
    return flexQuery("query {"+
            `flex {`+
            `  market(pairAddress: "${address}") {`+
            `    orderBook {`+
            "      bids {"+
            "        price"+
            "        amount"+
//            "        priceNumUnits"+
//            "        priceDenomUnits"+
//            "        amountUnits"+
            "      }"+
            "      asks {"+
            "        price"+
            "        amount"+
            "      }"+
            "    }"+
            "  }"+
            "}}")
        .then((data) => {
            if (!data || !data.flex) throw new Error(`Couldn't get flex order book`);
            return data.flex.market ? data.flex.market.orderBook : {bids: [], asks: []};
        }).catch((error) => {
            console.error(`flexOrderBook:error`, error);
            return {bids: [], asks: []};
        });
}

async function flexPriceHistory(ticker, resolution, from, countBack, to, firstDataRequest) {
    const pair = await flexPairByTicker(ticker);
    if (!pair) return null;

    let paramsString = '';
    if (resolution) paramsString += `${paramsString ? ', ' : ''}resolution:${resolution*60}`; //min to sec
    if (countBack) paramsString += `${paramsString ? ', ' : ''}countBack:${countBack}`;
    if (from) paramsString += `${paramsString ? ', ' : ''}startTime:${from}`;
    if (to) paramsString += `${paramsString ? ', ' : ''}endTime:${to}`;

    //console.log(`paramsString = ${paramsString}`);

    return flexQuery("query{"+
            `flex{`+
            `    market(pairAddress: "${pair.address}"){`+
            `      priceHistory(${paramsString}){`+
            "        nextTime"+
            "        prices{"+
            "           open"+
            "           close"+
            "           high"+
            "           low"+
            "           volume"+
            "           time"+
            "        }"+
            "      }"+
            "    }"+
            "  }"+
            "}")
        .then((data) => {
            if (data && data.flex && data.flex.market)
                return {
                    prices: data.flex.market.priceHistory.prices,
                    nextTime: data.flex.market.priceHistory.nextTime
                };
            return {prices: []};
        }).catch((error) => {
            console.error(`flexPriceHistory:error`, error);
        });
}

const API = {
  flexVersion,
  flexPairs,
  flexPairByTicker,
  flexPriceHistory,
  flexTime,
  flexBalance,
  getSymbolName,
  getSymbolDescription,
  flexOrderBook,
  subscribeBars,
  subscribeOrderbook,
  subscribeRecentTrades,
  subscribeUserOrders,
  subscribeUserWallets,
  subscribeUserTrades,
  subscribeBalance,
  unsubscribe,
  setEndpoint,
};

export default API;
