// @ts-nocheck
import callback from "./abi";

import { Debots, rpc } from "./client";
import DebotClient from "./client";

const browser = import("debot-browser");

const SUCCESS = 0;
//recover gas statuses
const CANCEL_ALL_ORDERS = 6;
const BURN_WALLETS = 7;
const BURN_EMPTY_WALLETS = 8;
const BURN_USER_ID = 9;
//errors
const ERR_NO_SUPER_ROOT = 20;
const ERR_WRONG_FC_CODE_HASH = 21;
const ERR_NEXT_SUPER_ROOT = 22;
const ERR_GET_METHOD = 23;
const ERR_NO_VERSION = 24;
const ERR_NO_GLOBAL_CONFIG = 25;
const ERR_FC_FROZEN = 26;
const ERR_USER_INDEX_FROZEN = 27;
const ERR_GRAPHQL_QUERY = 28;
const ERR_GRAPHQL_UNEXPECTED_RESULT = 29;
const ERR_LOW_BALANCE = 30;
const DEPLOY_INDEX_ERROR = 31;
const ERR_AUTH_INDEX_EXIST = 32;
const ERR_WALLET_NOT_ACTIVE = 33;
const ERR_WALLET_LOW_BALANCE = 34;
const ERR_FC_DEPLOY = 35;
const ERR_NO_WRAPPERS = 36;
const ERR_NO_POSSIBLE_WALLETS = 37;
const ERR_CONVERT_ADDRESS = 38;
const ERR_WALLET_QUERY_ERROR = 39;
const ERR_WALLET_COLLECT = 40;
const ERR_REBIND = 41;
const ERR_TRANSACTION = 42;
const ERR_FC_NOT_DEPLOYED = 43;
const ERR_USERID_NOT_DEPLOYED = 44;
const ERR_REBIND_WALLETS = 45;
const ERR_ADDRESS_CONVERTION = 46;
const ERR_UINT_CONVERTION = 47;
const ERR_BURN_EMPTY_WALLET = 48;
const ERR_BURN_WALLET = 49;
const ERR_CANCEL_ALL_ORDERS = 50;
const ERR_FC_WITHDRAW = 51;

export default class Recover {
  //recover manifest
  private static buildManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('recover')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.recoverAbi}
    }`);
  }

  //recover debot methods
  public static async withdrawFromFlexClient(flexClient, dst, amount):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling withdrawFromFlexClient...\n`);
    const manifest = Recover.buildManifest(
      "withdrawFromFlexClient",
      `{
        "flexClient": "${flexClient}",
        "dst": "${dst}",
        "amount": "${amount}"
      }`
    );
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.recoverBrowserHandle), manifest)
  }

  public static async removeUser(cb):Promise<any> {
    console.log(`Calling removeUser...\n`);
    let result = {status: undefined, cache: undefined};

    while (parseInt(result.status) !== SUCCESS) {
      const intStatus = result.status ? parseInt(result.status) : undefined;
      if (intStatus === undefined) {
        cb && cb(undefined);
        result = await Recover.recoverGas();
        //result.status = `${SUCCESS}`; //DEBUG!!!
      } else if (intStatus === CANCEL_ALL_ORDERS) {
        cb && cb(result);
        result = await Recover.cancelAllOrders(result.cache);
      } else if (intStatus === BURN_WALLETS) {
        cb && cb(result);
        result = await Recover.burnWallets(result.cache);
      } else if (intStatus === BURN_EMPTY_WALLETS) {
        cb && cb(result);
        result = await Recover.burnEmptyWallets(result.cache);
      } else if (intStatus === BURN_USER_ID) {
        cb && cb(result);
        result = await Recover.burnUserID(result.cache);
      }
      console.log('recoverGas result => ', result);
      if (parseInt(result.status) >= 20) break;
    }
    cb && cb(result);
    return result;
  }

  public static async recoverGas():Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling recoverGas...\n`);
    const manifest = Recover.buildManifest("recoverGas", `{}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.recoverBrowserHandle), manifest);
  }

  public static async cancelAllOrders(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling cancelAllOrders...\n`);
    const manifest = Recover.buildManifest("cancelAllOrders", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.recoverBrowserHandle), manifest)
  }

  public static async burnWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling burnWallets...\n`);
    const manifest = Recover.buildManifest("burnWallets", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.recoverBrowserHandle), manifest)
  }

  public static async burnEmptyWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling burnEmptyWallets...\n`);
    const manifest = Recover.buildManifest("burnEmptyWallets", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.recoverBrowserHandle), manifest)
  }

  public static async burnUserID(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling burnUserID...\n`);
    const manifest = Recover.buildManifest("burnUserID", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.recoverBrowserHandle), manifest)
  }
}
