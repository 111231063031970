export const SET_AUTH = "SET_AUTH";
export const SET_USER_GAS = "SET_USER_GAS";

export const SELECT_PAIR = "SELECT_PAIR";

export const SET_PAIRS = "SET_PAIRS";
export const LOAD_PAIRS = "LOAD_PAIRS";
export const SET_FAVOURITE_PAIRS = "SET_FAVOURITE_PAIRS";

export const SET_ORDERBOOK = "SET_ORDERBOOK";
export const LOAD_ORDERBOOK = "LOAD_ORDERBOOK";

export const SET_TRADES = "SET_TRADES";
export const LOAD_TRADES = "LOAD_TRADES";
export const UPDATE_TRADES = "UPDATE_TRADES";

export const SET_STATS = "SET_STATS";
export const LOAD_STATS = "LOAD_STATS";

export const SET_USER_WALLETS = "SET_USER_WALLETS";
export const LOAD_USER_WALLETS = "LOAD_USER_WALLETS";
export const UPDATE_USER_WALLETS = "UPDATE_USER_WALLETS";

export const SET_USER_TRADES = "SET_USER_TRADES";
export const LOAD_USER_TRADES = "LOAD_USER_TRADES";
export const UPDATE_USER_TRADES = "UPDATE_USER_TRADES";

export const SET_USER_FUNDS = "SET_USER_FUNDS";
export const LOAD_USER_FUNDS = "LOAD_USER_FUNDS";

export const SET_USER_OPEN_ORDERS = "SET_USER_OPEN_ORDERS";
export const LOAD_USER_OPEN_ORDERS = "LOAD_USER_OPEN_ORDERS";
export const UPDATE_USER_OPEN_ORDERS = "UPDATE_USER_OPEN_ORDERS";

export const SELECT_ORDERBOOK_ROW = "SELECT_ORDERBOOK_ROW";
export const SET_EXCHANGE_MODE = "SET_EXCHANGE_MODE";

export const SET_ORDER_MESSAGE = "SET_ORDER_MESSAGE";
export const SET_UPDATE_LINK = "SET_UPDATE_LINK";
