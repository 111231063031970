//const appStorage = localStorage;

/**
 * Property to find out if we are working in Electron-based Application
 * See: https://github.com/electron/electron/issues/2288#issuecomment-337858978
 */
const isElectron = window.navigator?.userAgent?.toLowerCase().indexOf(' electron/') > -1;

/**
 * Types of messages we can send to Electron's main process
 */
enum ElectronMessageTypes {
    RequestDeviceId = 'request-device-id',
    GetDownloadURL = 'get-download-url',
}

/**
 * Payload type for messages we can send to Electron
 */
type AtomicPayload = string | boolean | number;
type Payload = AtomicPayload | Record<string, AtomicPayload> | undefined;

/**
 * Function to form a message to Electron
 * @param type of the message to be sent to Electron
 * @param payload of the message to be sent to Electron
 * @returns a message object
 */
function formMessageToElectron(type: ElectronMessageTypes, payload: Payload = undefined) {
    return {
        toElectron: {
            type,
            payload,
        },
    };
}

/**
 * Function to parse the payload in order to cast it to the required type
 * @param payload - a specified payload
 * @returns a parsed payload
 */
function parsePayload<T extends Payload>(payload: any): T {
    try {
        return JSON.parse(payload) as T;
    } catch (_error) {
        // Failed to parse a payload
        return payload as T;
    }
}

/**
 * Function to get a single response of the desired message received from Electron
 * @param messageType - a specified message type
 * @returns a payload object
 */
async function getResponsePayloadForMessageWithTypeOnce<T extends Payload>(
    messageType: ElectronMessageTypes,
): Promise<T> {
    return new Promise<T>(resolve => {
        const listener = ({ data }: MessageEvent) => {
            // Check if the message is directed to Application from Electron
            const message = data && data.fromElectron;
            if (!message) {
                // Skip messages directed not to Electron
                return;
            }

            // Check the message type
            const { type } = message;
            if (!type) {
                console.error('Message from Electron received via the bridge does not have a type');
            }

            // Check for the `type` to equal to `messageType`
            if (type === messageType) {
                // Let's remove the listener once the response for the specified type is captured!
                // Note: next might cause a memory leak, but it's better to remove the listener,
                // once it's not needed anymore and hope for GC to clean the rest ;)
                window.removeEventListener('message', listener);
                // Resolve the received payload
                resolve(parsePayload<T>(message.payload));
            }
        };

        window.addEventListener('message', listener);
    });
}

/**
 * Function to subscribe on a payload of the desired messages received from Electron
 * @param messageType - a specified message type
 * @param callback - a callback with the payload
 * @returns a disposer to unsubscribe
 */
function subscribeOnPayloadForMessageWithType<T extends Payload>(
    messageType: ElectronMessageTypes,
    callback: (payload: T) => void,
): () => void {
    const listener = ({ data }: MessageEvent) => {
        // Check if the message is directed to Application from Electron
        const message = data && data.fromElectron;
        if (!message) {
            // Skip messages directed not to Electron
            return;
        }

        // Check the message type
        const { type } = message;
        if (!type) {
            console.error('Message from Electron received via the bridge does not have a type');
        }

        // Check for the `type` to equal to `messageType`
        if (type === messageType) {
            // Broadcast the received payload
            callback(parsePayload<T>(message.payload));
        }
    };

    window.addEventListener('message', listener);

    return () => {
        window.removeEventListener('message', listener);
    };
}

/**
 * Method to request a device ID for Electron-based Application
 * @returns a device ID
 */
async function requestDeviceId(): Promise<string> {
    if (!isElectron) {
        throw new Error('Trying to request a device ID for non-Electron based application');
    }

    const request = formMessageToElectron(ElectronMessageTypes.RequestDeviceId);
    window.postMessage(request);

    return getResponsePayloadForMessageWithTypeOnce<string>(ElectronMessageTypes.RequestDeviceId);
}

async function requestDownloadUrl(): Promise<string> {
    if (!isElectron) {
        throw new Error('Trying to request a update link for non-Electron based application');
    }

    const request = formMessageToElectron(ElectronMessageTypes.GetDownloadURL);
    window.postMessage(request);

    return getResponsePayloadForMessageWithTypeOnce<string>(ElectronMessageTypes.GetDownloadURL);
}

export const ElectronUtils = {
    requestDeviceId,
    isElectron,
    requestDownloadUrl,
};
