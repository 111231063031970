// @ts-nocheck
import callback from "./abi";

import { Debots, rpc } from "./client";
import DebotClient from "./client";

const browser = import("debot-browser");

export default class Assets {
  //assets manifest
  private static buildAssetsManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('assets')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.assetsAbi}
    }`);
  }

  //assets debot methods
  public static async getExtWallets(owner):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling getExtWallets...\n`);
    const manifest = Assets.buildAssetsManifest("getExtWallets", `{"owner": "${owner}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.assetsBrowserHandle), manifest)
  }

  public static async getIntWallets(flexClient, userId):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling getIntWallets...\n`);
    const manifest = Assets.buildAssetsManifest(
      "getIntWallets",
      `{"flexClient": "${flexClient}", "userId": "${userId}"}`
    );
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.assetsBrowserHandle), manifest)
  }

  public static async deposit(flexClient, userId, owner, srcWallet, amount, wrapperWallet):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deposit...\n`);
    const manifest = Assets.buildAssetsManifest(
      "deposit",
      `{"flexClient": "${flexClient}",
      "userId": "${userId}",
      "owner": "${owner}",
      "srcWallet": "${srcWallet}",
      "amount": "${amount}",
      "wrapperWallet": "${wrapperWallet}"
      }`
    );
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.assetsBrowserHandle), manifest)
  }

  public static async withdraw(flexClient, srcIntWallet, dstExtWalletOwner, amount):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling withdraw...\n`);
    const manifest = Assets.buildAssetsManifest(
      "withdraw",
      `{"flexClient": "${flexClient}",
      "srcIntWallet": "${srcIntWallet}",
      "dstExtWalletOwner": "${dstExtWalletOwner}",
      "amount": "${amount}"
      }`
    );
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.assetsBrowserHandle), manifest)
  }

  public static async sendTransaction(params) {
    return await rpc.sendTransaction(params);
  }
}
