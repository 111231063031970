// @ts-nocheck
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./AuthWeb.module.scss";
import classnames from "classnames/bind";

import { Modal, Button } from "react-bootstrap";

import {getDecimalsCount, shiftedBy, formatNumber} from "../utils";
import { getConfigByDomain } from "../../client/client";

const cn = classnames.bind(styles);

const SUPPORT_LINK = 'https://t.me/flexdexbeta';
const ContactSupport = () => {
  return (
    <div>
      Please contact&nbsp;
      <span role="button" className="text-decoration-underline" onClick={()=>window.open(SUPPORT_LINK)}>
        support.
      </span>
    </div>
  );
}

const TopItUp = ({to, topupLink}) => {
  return (
    <div>
      Please&nbsp;
      <span role="button" className="text-decoration-underline" onClick={()=>window.open(topupLink)}>
        top it up
      </span>
      {to ? ` to at least ${to} EVER.` : '.'}
    </div>
  );
};

const ERROR_DESCRIPTION = {
  20: {text: 'Flex Super Root contract not found.', isSupport: true},
  21: {text: 'Wrong Flex Client version.', isSupport: true},
  22: {text: 'Next super root not empty.', isSupport: true},
  23: {text: 'Get method error.', isSupport: true},
  24: {text: 'No version.', isSupport: true},
  25: {text: 'Flex Global Configuration contract not found.', isSupport: true},
  26: {text: 'Your Flex Client contract is frozen.', isSupport: true, displayFlexClient: true},
  27: {text: 'Your User account is frozen.', isSupport: true, displayFlexClient: true, displayUserIndex: true},
  28: {text: 'GraphQL query error.', isSupport: true},
  29: {text: 'Unexpected GraphQL result.', isSupport: true},
  30: {text: 'Your Flex Client balance is too low for this action.', isTopup: true, displayFlexClient: true, topupLink: 'https://docs.flexdex.fi/guides/troubleshooting#web-dapp'},
  31: {text: 'Failed to deploy Flex user account. Please try again later.'},
  32: {text: 'Flex user account already exists.', isSupport: true},
  33: {text: 'Your Surf wallet is not active.', isTopup: true, topupTo: 151, topupLink: 'https://help.ever.surf/en/support/solutions/articles/77000263394-how-to-get-evers-'}, //+activate it
  34: {text: 'Your Surf wallet balance is too low for this action.', isTopup: true, topupTo: 151, topupLink: 'https://help.ever.surf/en/support/solutions/articles/77000263394-how-to-get-evers-'},
  35: {text: 'Failed to deploy Flex Client contract. Please try again later.',},
  36: {text: 'Wrapper not found.', isSupport: true},
  37: {text: 'Wallets not found.', isSupport: true},
  38: {text: 'Cannot convert address from string.', isSupport: true},
  39: {text: 'GraphQL query error.', isSupport: true},
  40: {text: 'Can’t collect wallets.', isSupport: true},
  41: {text: 'Can’t rebind wallet.', isSupport: true},
  42: {text: 'Transaction error.', isSupport: true},
  43: {text: 'FlexClient not deployed.', isSupport: true},
  44: {text: 'UserIndex not deployed.', isSupport: true},
  45: {text: 'Can’t rebind wallets.', isSupport: true},
  46: {text: 'Cannot convert address from string.', isSupport: true},
  47: {text: 'Cannot get uint from string.', isSupport: true},
  48: {text: 'Failed to delete empty wallet. Please try again later.',},
  49: {text: 'Failed to delete Flex wallet. Please try again later.',}, //+<specify wallet token>
  50: {text: 'Failed to cancel open orders. Please try again later.',},
  51: {text: 'Failed to withdraw tokens from Flex Client. Please try again later.',},
  52: {text: 'You don’t have enough tokens in your wallet to deposit the required amount.'},
  53: {text: 'You have insufficient gas balance on your Flex Client to process this tokens withdrawal.'},
  54: {text: 'You have insufficient balance on your Flex Client.', displayFlexClient: true},
  55: {text: 'You have already recovered all available gas from your Flex Client.', displayFlexClient: true},
}

const RemoveUserLowFlexClientBalance = ({flexClient}) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <div>
    You have insufficient balance on your Flex client to cancel all your orders and burn all your token wallets.
    Please, cancel your orders manually in Flex UI and make sure you have
    at least <span className="text-success">20 EVER</span> on your
    Flex client address:
    </div>
    <p/>
    <small>{flexClient}</small>
    <p/>
    You will be able to recover all the remaining gas from your Flex client afterwards.
  </div>
);

const AuthLowFlexClientBalance = ({flexClient}) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <div>
      You have insufficient balance on your Flex Client to create a new user.
      <p/>
      Make sure you have at least <span className="text-success">150 EVER</span> on your Flex Client address:
    </div>
    <p/>
    <small>{flexClient}</small>
    <p/>
  </div>
);

const WithdrawLowFlexClientBalance = ({flexClient}) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <div>
    You have insufficient gas balance on your Flex Client to process this tokens withdrawal.
    Make sure you have at least <span className="text-success">1.6 EVER</span> on your Flex Client address:
    </div>
    <p/>
    <small>{flexClient}</small>
    <p/>
  </div>
);

const NothingToWithdraw = ({flexClient}) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    You have already recovered all available gas from your Flex Client:
    <p/>
    <small>{flexClient}</small>
    <p/>
  </div>
);

const TerminatedByUser = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-3 text-danger">
      Terminated by user.
    </div>
  );
}

const ErrorContent = ({error}) => {
  const { authorized } = useSelector((state: any) => state.dashboard);

  if (error?.sdkError === '810') return <TerminatedByUser />;

  if (parseInt(error.status) === 30 && error.operation === 'removeUser') return <RemoveUserLowFlexClientBalance flexClient={authorized?.flexClient} />;
  if (parseInt(error.status) === 55 && error.operation === 'recoverGas') return <NothingToWithdraw flexClient={authorized?.flexClient} />;
  if (parseInt(error.status) === 54 && error.operation === 'auth') return <AuthLowFlexClientBalance flexClient={authorized?.flexClient} />;
  if (parseInt(error.status) === 53 && error.operation === 'withdraw') return <WithdrawLowFlexClientBalance flexClient={authorized?.flexClient} />;

  const errorInfo = ERROR_DESCRIPTION[parseInt(error.status)];
  if (!errorInfo) return (
    <div className="d-flex w-100 overflow-auto flex-column align-items-start justify-content-center mt-3 text-danger">
      {error.toString()}
    </div>
  );

  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-3">
      <div className="text-danger">{`Error: (${error.status}) ${errorInfo.text}`}</div>
      {errorInfo.isSupport && <ContactSupport />}
      {errorInfo.isTopup && <TopItUp to={errorInfo.topupTo} topupLink={errorInfo.topupLink} />}
      {errorInfo.displayFlexClient &&
        <>
        <p/>
        Flex Client address:
        <small>{authorized?.flexClient}</small>
        <p/>
        </>
      }
      {errorInfo.displayUserIndex &&
        <>
        <p/>
        User Index address:
        <small>{authorized?.userIdAddress}</small>
        <p/>
        </>
      }
    </div>
  );
}

export const ErrorModal = (props: any) => {
  const {error, onOk, show} = props;
  const { authorized } = useSelector((state: any) => state.dashboard);

  useEffect(() => {
  }, [props.show]);

  return (
    <Modal
      id="flex-error-modal"
      show={show}
      onHide={()=>onOk()}
      keyboard={false}
      dialogClassName={cn("flex-modal")}
      contentClassName="border-0 p-0"
      centered
    >
      <div className="flex-modal d-flex flex-column align-items-center justify-content-center p-3 rounded text-white" style={{minHeight: 300, backgroundColor: '#161A1E'}}>
        <ErrorContent error={error}/>
        <Button
          className={cn("mt-2 w-100 btn-action")}
          style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
          onClick={onOk}>
          Ok
        </Button>
      </div>
    </Modal>
  );
}
