import { ActionTypes } from "../../types/action-types";
import {
  LOAD_PAIRS,
  SET_PAIRS,

  SET_STATS,
  LOAD_STATS,

  LOAD_ORDERBOOK,
  SET_ORDERBOOK,

  LOAD_TRADES,
  SET_TRADES,
  UPDATE_TRADES,

  SET_USER_WALLETS,
  LOAD_USER_WALLETS,
  UPDATE_USER_WALLETS,

  SET_USER_TRADES,
  LOAD_USER_TRADES,
  UPDATE_USER_TRADES,

  SET_USER_FUNDS,
  LOAD_USER_FUNDS,

  SET_USER_OPEN_ORDERS,
  LOAD_USER_OPEN_ORDERS,
  UPDATE_USER_OPEN_ORDERS,

  SET_USER_GAS,

  SET_UPDATE_LINK,
} from "../actions/constants";

import {
  TradingPairInfo,
  TradeData,
  TradeStatistic,
  OrderBookRow,
  UserWallet,
  UserOrder,
  UserFund,
  UserTradeData
} from "../../interfaces";

import {
  State,
  getInitialState,
  setLoadingState,
  setSuccessState
} from '../../utils/store/PromiseUtils';

interface IInitialState {
  pairs: State<TradingPairInfo<number>[]>,
  pairInfo: State<TradeStatistic | undefined>,
  orderbook: State<OrderBookRow[]>,
  trades: State<TradeData[]>,
  userWallets: State<UserWallet[]>,
  userOpenOrders: State<UserOrder[]>,
  userTrades: State<UserTradeData[]>,
  userFunds: State<UserFund[]>,
  userGas: State<number | undefined>,
  updateLink: State<any>,
}

const initialState: IInitialState = {
  pairs: getInitialState<TradingPairInfo<number>[]>([]),
  pairInfo: getInitialState<TradeStatistic | undefined>(undefined),
  orderbook: getInitialState<OrderBookRow[]>([]),
  trades: getInitialState<TradeData[]>([]),
  userWallets: getInitialState<UserWallet[]>([]),
  userOpenOrders: getInitialState<UserOrder[]>([]),
  userTrades: getInitialState<UserTradeData[]>([]),
  userFunds: getInitialState<UserFund[]>([]),
  userGas: getInitialState<number | undefined>(undefined),
  updateLink: getInitialState<any>(null),
};

export const flexReducer = (
  state: IInitialState = initialState,
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_UPDATE_LINK:
      return setSuccessState<any, IInitialState>(state, 'updateLink', payload);

    case SET_USER_GAS:
      return setSuccessState<number, IInitialState>(state, 'userGas', payload as number);

    case LOAD_PAIRS:
      return setLoadingState<TradingPairInfo<number>[], IInitialState>(state, 'pairs', []);
    case SET_PAIRS:
      return setSuccessState<TradingPairInfo<number>[], IInitialState>(state, 'pairs', payload as TradingPairInfo<number>[]);

    case LOAD_ORDERBOOK:
      return setLoadingState<Array<OrderBookRow> | undefined, IInitialState>(state, 'orderbook', undefined);
    case SET_ORDERBOOK:
      return setSuccessState<Array<OrderBookRow> | undefined, IInitialState>(state, 'orderbook', payload as Array<OrderBookRow>);

    case LOAD_STATS:
      return setLoadingState<TradeStatistic | undefined, IInitialState>(state, 'pairInfo', undefined);
    case SET_STATS:
      return setSuccessState<TradeStatistic, IInitialState>(state, 'pairInfo', payload as TradeStatistic);

    case LOAD_TRADES:
      return setLoadingState<TradeData[] | undefined, IInitialState>(state, 'trades', undefined);
    case SET_TRADES:
      return setSuccessState<TradeData[], IInitialState>(state, 'trades', payload as TradeData[]);
    case UPDATE_TRADES:
      const trades: TradeData[] = [...state.trades.data];
      const newTrades = payload as TradeData[];
      newTrades && newTrades.length && newTrades.forEach((newTrade: TradeData) => {
        const updatedTrade = trades.findIndex(trade => trade.id === newTrade.id);
        if (updatedTrade === -1) {
          trades.push(newTrade);
        } else {
          trades[updatedTrade] = newTrade;
        }
      });
      return setSuccessState<TradeData[], IInitialState>(state, 'trades', trades);

    case LOAD_USER_WALLETS:
      return setLoadingState<UserWallet[] | undefined, IInitialState>(state, 'userWallets', undefined);
    case SET_USER_WALLETS:
      return setSuccessState<UserWallet[], IInitialState>(state, 'userWallets', payload as UserWallet[]);
    case UPDATE_USER_WALLETS:
      const userWallets: UserWallet[] = [...state.userWallets.data];
      const updatedWallets = payload as UserWallet[];
      updatedWallets && updatedWallets.length && updatedWallets.forEach((updatedWallet: UserWallet) => {
        const idx = userWallets.findIndex((w: UserWallet) =>
          ((w.address && w.address === updatedWallet.address) ||
          (!w.address && w.ticker === updatedWallet.ticker))
        );
        if (idx !== -1) userWallets.splice(idx, 1);
        userWallets.push(updatedWallet);
      });
      userWallets.sort((w1, w2) => w1.ticker.localeCompare(w2.ticker));
      return setSuccessState<UserWallet[], IInitialState>(state, 'userWallets', userWallets);
    case LOAD_USER_TRADES:
      return setLoadingState<UserTradeData[] | undefined, IInitialState>(state, 'userTrades', undefined);
    case SET_USER_TRADES:
      return setSuccessState<UserTradeData[], IInitialState>(state, 'userTrades', payload as UserTradeData[]);
    case UPDATE_USER_TRADES:
      const userTrades: UserTradeData[] = [...state.userTrades.data];
      const newUserTrades = payload as UserTradeData[];
      newUserTrades && newUserTrades.length && newUserTrades.forEach(newTrade => {
        if (!userTrades.find(userTrade => userTrade.id === newTrade.id)) userTrades.push(newTrade);
      });
      return setSuccessState<UserTradeData[], IInitialState>(state, 'userTrades', userTrades);
    case LOAD_USER_FUNDS:
      return setLoadingState<UserFund[] | undefined, IInitialState>(state, 'userFunds', undefined);
    case SET_USER_FUNDS:
      return setSuccessState<UserFund[], IInitialState>(state, 'userFunds', payload as UserFund[]);

    case LOAD_USER_OPEN_ORDERS:
      return setLoadingState<UserOrder[] | undefined, IInitialState>(state, 'userOpenOrders', undefined);
    case SET_USER_OPEN_ORDERS:
      return setSuccessState<UserOrder[], IInitialState>(state, 'userOpenOrders', payload as UserOrder[]);
    case UPDATE_USER_OPEN_ORDERS:
      return setSuccessState<UserOrder[], IInitialState>(state, 'userOpenOrders', payload as UserOrder[]);

    default:
      return state;
  }
};
