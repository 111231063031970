import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./AuthWeb.module.scss";
import stylesInput from '../../components/form/Input.module.scss';

import classnames from "classnames/bind";

import { Modal, Button } from "react-bootstrap";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

import { Input, InputNumber, /*Button*/ } from "../../components";
import {getDecimalsCount, shiftedBy} from "../utils";

const cn = classnames.bind(styles);
const cnForm = classnames.bind(stylesInput);

interface FormValues {
  amount: string;
}

export const AuthWeb = (props: any) => {
  const {authorized, onOk, onDeploy, onError, error, show} = props;
  let authStatus = authorized ? parseInt(authorized?.status) : -1;
  const authFlexClient = authorized ? authorized?.flexClient : '';

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .test(
        'is-decimal',
        'invalid decimal',
        value => value ? getDecimalsCount(value) <= 1 : true
      )
      .typeError('This one should be a number')
      .required('')
  });

  const Formik = useFormik({
    initialValues: {
      amount: '0',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {},
  });

  const renderStage = (stage: any, status: any) => {
    let success = false;
    let size = 'sm';
    let text = '';
    let render = true;

    if (stage === 'total') {
      success = (status === 0);
      size = 'lg';
    }

    if (stage === 'prepare') { //deploy sign
      success = (status === 0) || status > 1;
      text = 'Prepare data';
      render = success || status === 1;
    }

    if (stage === 'deploy-client') { //deploy client
      success = (status === 0) || status > 2;
      text = 'Deploy Flex Client';
      render = success || status === 2;
    }

    if (stage === 'deploy-user') { //deploy user
      success = (status === 0) || status > 3;
      text = 'Deploy User Index';
      render = success || status === 3;
    }

    if (stage === 'rebind-wallets') { //rebind
      success = (status === 0);
      text = 'Rebind Wallets';
      render = success || status === 4;
    }

    const className = cn(
      `circle-${size}`,
      {"success": success, "progress": !success}
    );

    if (!render) return null;

    return (
      <div className="d-flex flex-row align-items-center mt-2">
        <div
          className={className}
        >
          {success ?
            <CheckIcon /> :
            <CircularProgress color="inherit" style={size === 'sm' ? {width:18, height: 18} : {width:24, height: 24}}/>
          }
        </div>
        <small className="ms-2">{text}</small>
      </div>
    );
  }
  return (
    <Modal
      id="auth-modal"
      show={show}
      onHide={()=>{}}
      backdrop="static"
      keyboard={false}
      dialogClassName={cn("flex-modal")}
      contentClassName="border-0 p-0"
      centered
    >
      <div className="flex-modal d-flex flex-column align-items-center justify-content-center p-3 rounded text-white" style={{minHeight: 300, backgroundColor: '#161A1E'}}>
        {error ?
        <div className="d-flex flex-column align-items-center justify-content-center mt-3 text-danger">
          {error.toString()}
          <Button className={cn("mt-2 w-100 btn-action")} style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}} onClick={onError}>
            Close
          </Button>
        </div>
        : authStatus === 3 && authorized?.previousAuthState === null ?
        <div className="d-flex w-100 flex-column gap-3 align-items-center justify-content-center">
          <Button
            className={cn("w-100 btn-action")}
            style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
            onClick={()=>onOk('createUser')}
          >
            Create user
          </Button>
          <Button
            className={cn("w-100 btn-action")}
            style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
            onClick={()=>onOk('recoverGas')}
          >
            Recover gas
          </Button>
        </div>
        : authStatus === 2 ?
        <div className="d-flex flex-column text-left">
          Welcome to Flex DEX!
          <p/>
          You don't have Flex client smart contracts yet.
          <p/>
          Let's deploy them.
          <p/>
          Deposit 150 EVERS or more to your gas balance to cover deployment and ongoing fees.
          <div className="mt-3">
            <FormikProvider value={Formik}>
              <form onSubmit={Formik.handleSubmit}>
                <InputNumber<FormValues>
                  className={"text-white"}
                  valueTester={(value: any )=>{
                    if (getDecimalsCount(value) <= 1) return value; //new value
                    return Formik.values.amount; //prev value
                  }}
                  composition={"substitute"}
                  name={"amount"}
                  title={"Amount"}
                  placeholder={"0"}
                  units={'EVER'}
                  style={{backgroundColor:'#1E2428', height: 56}}
                  inputGroupStyle={{backgroundColor:'#1E2428'}}
                />
              </form>
            </FormikProvider>
            <small className="text-muted">Min. 150-250,000 max.</small>
            <Button
              className={cn("mt-3 w-100 btn-action")}
              style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
              onClick={()=>onDeploy(shiftedBy(Formik.values.amount, 9))}
            >
              Deploy
            </Button>
          </div>
        </div>
        : authStatus === 0 ?
        <div className="d-flex flex-column align-items-center justify-content-center text-center">
          {renderStage('total', authStatus)}
          <div className="mt-3">
            <p/>
            Flex Client address:
            <p/>
            <small>{authFlexClient}</small>
            <Button
              className={cn("mt-3 w-100 btn-action")}
              style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
              onClick={onOk}
            >
              OK
            </Button>
          </div>
        </div>
        : authStatus === 4 ?
        <div className="d-flex flex-column align-items-center justify-content-center">
          {renderStage('total', authStatus)}
          <div className="mt-3">
            {renderStage('rebind-wallets', authStatus)}
          </div>
        </div>
        :
        <div className="d-flex flex-column align-items-center justify-content-center">
          {renderStage('total', authStatus)}
          <div className="mt-3">
            {renderStage('prepare', authStatus)}
            {renderStage('deploy-client', authStatus)}
            {renderStage('deploy-user', authStatus)}
          </div>
        </div>
        }
      </div>
    </Modal>
  );
}
