// @ts-nocheck
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Button } from "react-bootstrap";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

import { hasSurfKeeperProvider } from '@eversurf/surfkeeper-provider';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import classnames from "classnames/bind";

import Assets from "../../client/assets";

import styles from "./AuthWeb.module.scss";
import stylesInput from '../../components/form/Input.module.scss';

import { Input, InputNumber, Picker /*Button*/ } from "../../components";
import {getDecimalsCount, shiftedBy, formatNumber} from "../utils";
import { getConfigByDomain } from "../../client/client";

const cn = classnames.bind(styles);
const cnForm = classnames.bind(stylesInput);

interface FormValues {
  amount: string;
}

export const AssetsWeb = (props: any) => {
  const {mode, onOk, onError, show} = props;

  const { authorized } = useSelector((state: any) => state.dashboard);
  const [wallets, setWallets] = useState<any>([]);
  const [selectedWallet, setSelectedWallet] = useState<any>(null);
  const [ready, setReady] = useState(false);

  let authStatus = authorized ? parseInt(authorized?.status) : -1;
  const flexClient = authorized ? authorized?.flexClient : '';
  const userId = authorized ? authorized?.userId : '';
  const userIdAddress = authorized ? authorized?.userIdAddress : '';

  const owner = authorized ? authorized?.extension?.address : ''; //???

  console.log('AssetsWeb::authorized =>', authorized);

  useEffect(()=>{
    if (!props.show) {
      Formik.setFieldValue('amount', '0');
      return;
    }

    setReady(false);
    if (props.mode === 'deposit') {
      Assets.getExtWallets(owner).then((result)=>{
        console.log('getExtWallets =>', result);
        const ws = result.extWallets;
        if (ws && ws.length) setSelectedWallet(ws[0]);
        setWallets(ws);
      }).catch(err=>{
        onError('getExtWallets error: ' + err.toString());
      }).finally(()=>{
        setReady(true);
      });
    } else if (props.mode === 'withdraw') {
      Assets.getIntWallets(flexClient, userId)
      .then((result)=>{
        console.log('getIntWallets =>', result);
        const ws = result.intWallets;
        if (ws && ws.length) setSelectedWallet(ws[0]);
        setWallets(ws);
      })
      .catch((err)=>{
        onError('getIntWallets error: ' + err.toString());
      })
      .finally(()=>{
        setReady(true);
      });
    } else {
        setSelectedWallet({decimals: 9, symbol: 'Ever'});
        setReady(true);
    }
  }, [props.show, props.mode]);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .test(
        'is-decimal',
        'invalid decimal',
        value => value ? getDecimalsCount(value) <= selectedWallet?.decimals : true
      )
      .typeError('This one should be a number')
      .required('')
  });

  const Formik = useFormik({
    initialValues: {
      amount: '0',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting, resetForm }) => {},
  });

  const renderLoading = () => (
      <div className="d-flex flex-row align-items-center mt-2">
        <div className="">
            <CircularProgress color="inherit" style={{width:24, height: 24}}/>
        </div>
      </div>
    );

  /*const renderError = () =>
    (<div className="d-flex flex-column align-items-center justify-content-center mt-3 text-danger">
      {error ? error.toString() : ''}
      <Button
        className={cn("mt-2 w-100 btn-action")}
        style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
        onClick={()=>{setError(null); onOk();}}>
        Ok
      </Button>
    </div>)*/


  const topupGas = () => {
    setReady(false);
    onOk();
    Formik.setFieldValue('amount', '0');
    console.log(`send to ${userIdAddress}...`);
    Assets.sendTransaction({
      amount: shiftedBy(Formik.values.amount, 9), // in nano-tokens, i.e. 10 tokens, EVER only
      bounce: true,
      comment: 'topup gas',
      net: getConfigByDomain().alias,
      to: userIdAddress, //'0:b76b532fbe72307bff243b401d6792d5d01332ea294a0310c0ffdf874026f2b9',
    })
    .then(result => {
      console.log(`send to ${userIdAddress}... ready`, result);
      if (result.result && result.result.shard_block_id) {
        //onOk
      } else if (typeof(result) === 'string') {
        console.log('Topup error: ', result);
        onError('Topup gas error: ' + result.toString());
      }
    }).catch(err => {
      console.log(`send to ${userIdAddress}... failed`, err);
      onError('Topup gas error: ' + err.toString());
    }).finally(() => {
      setReady(true);
    });
  }

  const withdraw = () => {
    setReady(false);
    //flexClient, userId, owner, srcWallet, amount, wrapperWallet
    onOk();
    Formik.setFieldValue('amount', '0');
    Assets.withdraw(
      flexClient,
      selectedWallet?.addr,
      owner,
      shiftedBy(Formik.values.amount, selectedWallet?.decimals*1.0),
    ).then((result)=>{
      console.log('withdraw returned => ', result);
      if (result.status !== '0') {
        onError({operation: 'withdraw', ...result}/*'Withdraw error: ' + result.status*/);
      } /*else {
        onOk();
      }*/
    }).catch(err => {
      onError('Withdraw error: ' + err.toString());
    }).finally(()=>{
      setReady(true);
    });
  };

  const deposit = () => {
    setReady(false);
    //flexClient, userId, owner, srcWallet, amount, wrapperWallet
    onOk();
    Formik.setFieldValue('amount', '0');
    Assets.deposit(
      flexClient,
      userId,
      owner,
      selectedWallet?.addr,
      shiftedBy(Formik.values.amount, selectedWallet?.decimals*1.0),
      selectedWallet?.wrapperWallet,
    ).then((result)=>{
      console.log('deposit returned => ', result);
      if (result.status !== '0') {
        onError({operation: 'deposit', ...result});
      } /*else {
        onOk();
      }*/
    }).catch((err) => {
      onError('Deposit error: ' + err.toString());
    }).finally(() => {
      setReady(true);
    });
  };

  const submitHandler = props.mode === 'deposit' ? deposit : props.mode === 'deposit-gas' ? topupGas : withdraw;
  const onKeyPress = (event: any) => {
    if (event.code === 'Enter') {
      submitHandler && submitHandler();
    }
  };

  return (
    <Modal
      id="assets-modal"
      show={show}
      onHide={()=>onOk()}
      keyboard={false}
      dialogClassName={cn("flex-modal")}
      contentClassName="border-0 p-0"
      centered
    >
      <div className="flex-modal d-flex flex-column align-items-center justify-content-center p-3 rounded text-white" style={{minHeight: 300, backgroundColor: '#161A1E'}}>
        {/*error ? renderError() : */(!ready ? renderLoading() :
        <div className="d-flex w-100 flex-column text-left">
          <div className="mt-3">
            <FormikProvider value={Formik}>
              <form onSubmit={Formik.handleSubmit}>
                {props.mode !== 'deposit-gas' && <div className={cnForm("paragraph paragraph-footnote", "input-title", "text-muted", "mb-1")}>
                  Available assets
                </div>}
                {props.mode !== 'deposit-gas' && wallets?.length && <Picker
                  inputGroupStyle={{backgroundColor:'#1E2428', borderRadius: 10}}
                  label="Available assets"
                  fieldCurrency={'title'}
                  fieldAmount={'label'}
                  callback={(e) => {
                    const selected = wallets.filter((w: any) => w.addr === e.value);
                    if (selected && selected.length) setSelectedWallet(selected[0]);
                  }}
                  options={wallets.map((w: any) => ({
                    label: formatNumber(shiftedBy(w.balance, -w.decimals*1), w.decimals*1),
                    title: w.symbol.toUpperCase(),
                    value: w.addr,
                  }))}
                />}
                {!!selectedWallet && <InputNumber<FormValues>
                  autoComplete="off"
                  className={"text-white"}
                  valueTester={(value: any )=>{
                    if (getDecimalsCount(value) <= selectedWallet?.decimals) return value; //new value
                    return Formik.values.amount; //prev value
                  }}
                  composition={"substitute"}
                  name={"amount"}
                  title={props.mode === 'deposit' ? "Deposit amount" : props.mode === 'deposit-gas' ? "Top up gas" : "Withdraw amount"}
                  placeholder={"0"}
                  units={props.mode === 'deposit-gas' ? 'EVER' : selectedWallet?.symbol || ''}
                  style={{backgroundColor:'#1E2428', height: 56}}
                  inputGroupStyle={{backgroundColor:'#1E2428'}}
                  onKeyPress={onKeyPress}
                />}
              </form>
            </FormikProvider>
            <Button
              className={cn("mt-3 w-100 btn-action")}
              style={{minHeight: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
              onClick={submitHandler}
            >
              {props.mode === 'deposit' || props.mode === 'deposit-gas' ?
                `Deposit ${Formik.values.amount ? formatNumber(Formik.values.amount) : ''} ${selectedWallet?.symbol || 'Ever'}`
                :
                `Withdraw ${Formik.values.amount ? formatNumber(Formik.values.amount) : ''} ${selectedWallet?.symbol || 'Ever'}`
              }
            </Button>
          </div>
        </div>
        )}
      </div>
    </Modal>
  );
}
