// @ts-nocheck
import callback from "./abi";

import { Debots } from "./client";
import DebotClient from "./client";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Utils from "./utils";
import { ElectronUtils } from './ElectronUtils';

const browser = import("debot-browser");

export default class Auth {
  public static async disconnect() {
    const debotClient = await DebotClient.get();
    debotClient.disconnect();
  }

  public static async authorize(cb?: any, authObj?: any, deployValue?: any): Promise<any | undefined> {
    console.log('authorize call....');
    const debotClient = await DebotClient.get();
    let webSurf;
    try {
      webSurf = await debotClient.getExtension();
    } catch (err) {
      cb(null, err);
      return;
    }

    console.log('authorize call.... webSurf =', webSurf)
    if (!webSurf) {
      cb(null, "Extension isn't connected");
      return;
    }

    const authData = {flexClient: authObj?.flexClient, userId: authObj?.userId, userIdAddress: authObj?.userIdAddress};

    return new Promise<string | undefined>(async (resolve, reject) => {
      browser.then(() => {
        let value = null;
        const status = authObj ? authObj.status : undefined;

        switch (status) {
          case '1':
            Auth.deployFlexClientSign(authObj.cache).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          case '2':
            Auth.deployFlexClient(authObj.cache, deployValue).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          case '3':
            Auth.deployUserId(authObj.cache).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          case '4':
            Auth.reBindWallets(authObj.cache).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          default:
            Auth.auth().then(res=>({previousAuthState: null, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
        }
      }, error => {
        reject();
      });
    });
  }

  //auth manifest
  private static buildAuthManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('auth')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.authExtAbi}
    }`);
  }

  //auth debot methods
  private static async auth():Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling auth...\n`);
    const manifest = Auth.buildAuthManifest("auth", `{}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async deployUserId(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deployUserId...\n`);
    const manifest = Auth.buildAuthManifest("deployUserId", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async deployFlexClientSign(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deployFlexClientSign...\n`);
    const manifest = Auth.buildAuthManifest("deployFlexClientSign", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async deployFlexClient(cache, value):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deployFlexClient...\n`);
    const manifest = Auth.buildAuthManifest("deployFlexClient", `{"cache": "${cache}", "value": "${value}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async reBindWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling reBindWallets...\n`);
    const manifest = Auth.buildAuthManifest("reBindWallets", `{"cache": "cache"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }
}
