import {
  SET_AUTH,
  SET_FAVOURITE_PAIRS,
  SELECT_ORDERBOOK_ROW,
  SET_EXCHANGE_MODE,
  SELECT_PAIR,
  SET_USER_GAS
} from "./constants";

import {
  TradingPairInfo,
  OrderBookRow,
  exchangeMode
} from "../../interfaces";

export const setUserGas = (value: number | undefined) =>
  ({
    type: SET_USER_GAS,
    payload: value,
  } as const);

export const setAuthorised = (value: any) =>
  ({
    type: SET_AUTH,
    payload: value,
  } as const);

export const selectOrderBookRow = (value: OrderBookRow) =>
({
  type: SELECT_ORDERBOOK_ROW,
  payload: value,
} as const);

export const setExchangeMode = (value: exchangeMode) =>
  ({
    type: SET_EXCHANGE_MODE,
    payload: value,
  } as const);

export const setFavouritePairs = (value: TradingPairInfo<number>) =>
  ({
    type: SET_FAVOURITE_PAIRS,
    payload: value,
  } as const);

export const setPair = (value: TradingPairInfo<number>) =>
({
  type: SELECT_PAIR,
  payload: value,
} as const);
