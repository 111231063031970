import { FunctionComponent } from "react";
import styles from "./Stats.module.scss";
import classnames from "classnames/bind";
import { Skeleton } from "..";

const cn = classnames.bind(styles);

export const Stats = ({
  className = "",
  classNameTitle = "",
  classNameValue = "",
  style = {},
  styleTitle = {},
  styleValue = {},
  title,
  value,
  delta,
  valueComponent = null,
}: {
  className?: string,
  classNameTitle?: string,
  classNameValue?: string,
  style?: any,
  styleTitle?: any,
  styleValue?: any,
  title: string,
  value: string,
  valueComponent: any,
  delta?: {
    value: number,
    units: string,
  },
}) => {
  return <div
    className={cn("pair-stats", className, {"up": delta && delta.value > 0, "down": delta && delta.value < 0})}
    style={style}
  >
    <div className={cn("pair-stats-header", "paragraph", "paragraph-label", classNameTitle)} style={styleTitle}>
      {title}
    </div>
    {valueComponent ||
    <div className={cn("pair-stats-body", "paragraph", "paragraph-footnote", classNameValue)} style={styleValue}>
      {value} &nbsp;&nbsp;{delta && <span>{delta && delta.value > 0 ? "+" : null}{delta.value.toLocaleString("en", {minimumFractionDigits: 2})}&thinsp;{delta.units}</span>}
    </div>
    }
  </div>;
};

export const StatsSkeleton = ({ className = ""}: {
  className?: string,
}) => {
  return <div className={cn("pair-stats-skeleton", className)}>
      <div className={cn("pair-stats-header")}>
        <Skeleton wide="half"/>
      </div>
      <div className={cn("pair-stats-body")}>
        <Skeleton variant={className ==="green" ? className : "primary"} />
      </div>
  </div>;
};

export default Stats;
