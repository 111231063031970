import moment from "moment";
import BigNumber from "bignumber.js";

export const shiftedBy = (x: number | string, decimalsCount: number) => {
  const bn = new BigNumber(x || 0);
  return bn.shiftedBy(decimalsCount).toFixed();
}

export const bnMultiply = (a: number | string, b: number | string) => {
  const bnA = new BigNumber(a || 0);
  const bnB = new BigNumber(b || 0);
  const bnTotal = bnA.multipliedBy(bnB);
  return bnTotal.toFixed();
}

export const bnLt = (a: number | string, b: number | string) => {
  const bnA = new BigNumber(a || 0);
  const bnB = new BigNumber(b || 0);
  return bnA.lt(bnB);
}

export const cutNumber = (x: number | string, decimalsCount: number) => {
  const bn = new BigNumber(x);
  if ((bn.dp() || 0) <= decimalsCount) return bn.toFixed();
  return bn.toFixed(decimalsCount);
}

export const getDecimalsCount = (x: number | string) => {
  return new BigNumber(x).dp() || 0;
}

export const formatNumber = (x: number | string, decimalsCount?: number) => {
  return new BigNumber(x).toFormat(decimalsCount);
}

export const isExpired = (tmstmp: number) => {
  const agoSec = Date.now()/1000 - tmstmp;
  return agoSec > 0;
}

export const isToday = (tmstmp: number) => {
  const tmstmpDate = new Date(tmstmp*1000);
  const today = new Date();
  return tmstmpDate.getDate() == today.getDate() &&
    tmstmpDate.getMonth() == today.getMonth() &&
    tmstmpDate.getFullYear() == today.getFullYear()
}

export const formatDate = (tmstmp: number) => {
  const dateStr = isToday(tmstmp) ?
    moment.unix(tmstmp).format("HH:mm:ss") :
    moment.unix(tmstmp).format("YYYY-MM-DD HH:mm:ss");
  return dateStr;
}

export const isWithinLast24H = (tmstmp: number) => {
  const agoSec = Date.now()/1000 - tmstmp;
  return (agoSec < 60*60*24);
}

export const formatTime = (tmstmp: number) => {
  return moment.unix(tmstmp).format("HH:mm:ss");
}
