import React, { useRef, useEffect } from "react";
import { Button as BsButton, ButtonProps as BsButtonProps } from "react-bootstrap";
import styles from './Button.module.scss';

export interface ButtonProps extends Omit<BsButtonProps, 'variant'|'size'> {
  size?: BsButtonProps['size'] | "xl",
  variant?: BsButtonProps['variant'] | "primary" | "prominent" | "transparent" | "semitransparent",
  disabled?: boolean,
  className?: string,
  children?: React.ReactNode,
  glowing?: boolean,
  theme?: any,
  icon?: React.ReactNode | string,
}

export const Button = (props: ButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null)

  const hoverHandler = (e:MouseEvent) => {
    if (e.currentTarget) {
      (e.currentTarget as HTMLButtonElement).style.setProperty('--x', e.offsetX+"px");
      (e.currentTarget as HTMLButtonElement).style.setProperty('--y', e.offsetY+"px");
    }
  };

  useEffect(() => {
    if (ref.current && props.glowing) {
      ref.current.addEventListener("mousemove", hoverHandler);
    }
    return () => {
      if (ref.current && props.glowing) {
        ref.current.removeEventListener("mousemove", hoverHandler);
      }
    }
  }, [ref.current])
  const newProps = {...props, size: undefined, variant: undefined, glowing: undefined} as BsButtonProps;
  if (props.size === "xl") {
    newProps.className = newProps.className ? newProps.className+' '+styles['btn-xl'] : styles['btn-xl'];
  } else if (props.size) {
    newProps.className = newProps.className ? newProps.className+' '+styles['btn-'+props.size] : styles['btn-'+props.size];
  }
  if (typeof props.icon === "string") {
    newProps.className = newProps.className ? `${newProps.className} ${styles['btn-icon']} ${styles['btn-icon-'+props.icon]}` : `${styles['btn-icon']} ${styles['btn-icon'+props.icon]}`;
  } else {
    newProps.children = <>{props.icon}{newProps.children}</>;
  }
  if (props.variant) {
    newProps.className = newProps.className ? `${newProps.className} ${styles['btn-'+props.variant]}` : styles['btn-'+props.variant];
  }
  return <BsButton ref={ref} {...newProps} className={`${styles.btn} ${newProps.className}`}/>;
};

export default Button;
