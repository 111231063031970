import { Dispatch, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../types";

import DebotTrade from "../client/trade";

import {
  loadStats,
  setStats,
  loadUserWallets,
  updateUserWallets,
  loadUserTrades,
  updateUserTrades,
  loadUserOpenOrders,
  updateUserOpenOrders,
  setExchangeMode,
  setUserGas,
} from "../redux/actions";

import {
  OrderBookRow,
  TradingPairInfo,
  TradeStatistic,
  exchangeMode,
  UserWallet,
  UserOrder,
  TradeStatus,
  Authorized,
  UserTradeData
 } from "../interfaces";

import FlexAPI from '../flex_charts/api/flexAPI';

import {
  STATS_SUBSCRIPTION_ID,
  USER_ORDERS_SUBSCRIPTION_ID,
  USER_WALLETS_SUBSCRIPTION_ID,
  USER_TRADES_SUBSCRIPTION_ID,
  USER_GAS_SUBSCRIPTION_ID,
  FLEXCLIENT_GAS_SUBSCRIPTION_ID,
} from './subscriptions';

export interface IUseDashboard {
  selectedPair: TradingPairInfo<number> | undefined,
  selectedOrderBookRow: OrderBookRow | undefined,
  exchangeMode: exchangeMode,
  setExchangeMode: any,
  trade: any,
  cancelOrder: any,
  chartMode: string,
  pairInfo: TradeStatistic | undefined,
  setChartMode: any,
  authorized: Authorized | undefined,
  tradeLoading: boolean,

  userOpenOrders: UserOrder[],
  userWallets: UserWallet[],
  userTrades: UserTradeData[],
  userGas: number | undefined,

  tradeStatus: TradeStatus | undefined,
  setTradeStatus: (tradeStatus: TradeStatus | undefined) => void,
}

export const useDashboard = (): IUseDashboard => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { authorized, selectedPair, selectedOrderBookRow, exchangeMode } = useSelector((state: RootState) => state.dashboard);
  const {
    pairInfo: {data: pairInfo},
    userWallets: {data: userWallets},
    userTrades: {data: userTrades},
    userOpenOrders: {data: userOpenOrders},
    userGas: {data: userGas},
  } = useSelector((state: RootState) => state.flex);

  const [ chartMode, setChartMode ] = useState<string>('chart');
  const [ tradeStatus, setTradeStatus ] = useState<TradeStatus | undefined>();
  const [ tradeLoading, setTradeLoading ] = useState<boolean>(false);

  const setExchangeMode_ = (value: exchangeMode):void => {
    dispatch(setExchangeMode(value));
  }

  const cancelOrder = (
    pairAddress: string,
    sell: boolean,
    price: string,
    orderId: string,
    callback: (a: any) => void
  ): void => {
    try {
      DebotTrade.cancelOrder(
        authorized?.extension.publicKey || '',
        authorized?.flexClient || '',
        authorized?.userId || '',
        pairAddress, sell, price, orderId
      ).then((value)=>{
        setTradeStatus({...value, additionalInfo: 'cancelOrder'});
        callback && callback(value);
        setTimeout(() => {
          setTradeStatus(undefined);
        }, 6000)
      });
    } catch (err) {
      console.log('cancelOrder err => ', err)
    }
  }

  const trade = ({
    sell,
    price,
    amount,
    ioc,
    post,
    tradeMode,
  } : {
    tradeMode: string,
    sell: boolean,
    price: string,
    amount: string,
    ioc: boolean,
    post: boolean,
  },
  callback: (a: any)=>void
  ): void => {
    if (!selectedPair) return;
    console.log(`pass to trade: price=${price} amount=${amount}`);
    setTradeLoading(true);
    console.log('limitOrder::authorized =>', authorized);
    let func = () => {return new Promise((resolve) => resolve(DebotTrade.limitOrder(
      authorized?.extension.publicKey || '',
      authorized?.flexClient || '',
      authorized?.userId || '',
      selectedPair.address,
      sell,
      ioc,
      post,
      amount,
      price,
    )))};
    if (tradeMode === 'market') func = () => {return new Promise((resolve) => resolve(DebotTrade.marketOrder(
      authorized?.extension.publicKey || '',
      authorized?.flexClient || '',
      authorized?.userId || '',
      selectedPair.address,
      sell,
      amount,
    )))};

    func().then((value: any) => {
      // enable button
      console.log('result, value: ', value);
      setTradeLoading(false);
      callback && callback(value);
      // show toast notification
      setTradeStatus({...value, additionalInfo: tradeMode});
      setTimeout(() => {
        setTradeStatus(undefined);
      }, 6000)
    }).catch(err => {
      console.error(err);
    })
    .finally(() => {
      setTradeLoading(false);
    });
  }

  // Subscribe User Gas
  useEffect(() => {
    if (authorized) {
      if (!(window as any).totalGas) (window as any).totalGas = {userIdAddress: 0, flexClient: 0};
      Promise.all([
        FlexAPI.flexBalance(authorized?.userIdAddress),
        FlexAPI.flexBalance(authorized?.flexClient),
      ]).then((balances: (number | undefined)[]) => {
        console.log('balances (user, flex) => ', balances);
        (window as any).totalGas.userIdAddress = balances[0] || 0;
        (window as any).totalGas.flexClient = balances[1] || 0;
        const gas = balances.reduce((sum, balance) => (sum || 0) + (balance || 0), 0);
        dispatch(setUserGas(gas));
      })

      FlexAPI.unsubscribe(USER_GAS_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeBalance(authorized?.userIdAddress, USER_GAS_SUBSCRIPTION_ID, (balance: number | undefined) => {
          console.log('UPDATE USER GAS => ', balance);
          (window as any).totalGas.userIdAddress = (balance || 0);
          dispatch(setUserGas((window as any).totalGas.userIdAddress + (window as any).totalGas.flexClient));
        });
      });

      FlexAPI.unsubscribe(FLEXCLIENT_GAS_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeBalance(authorized?.flexClient, FLEXCLIENT_GAS_SUBSCRIPTION_ID, (balance: number | undefined) => {
          console.log('UPDATE FLEX CLIENT GAS => ', balance);
          (window as any).totalGas.flexClient = (balance || 0);
          dispatch(setUserGas((window as any).totalGas.userIdAddress + (window as any).totalGas.flexClient));
        });
      });
    }

    return () => {
      FlexAPI.unsubscribe(USER_GAS_SUBSCRIPTION_ID);
      FlexAPI.unsubscribe(FLEXCLIENT_GAS_SUBSCRIPTION_ID);
    }
  }, [authorized?.userIdAddress, authorized?.flexClient]);

  // Subscribe User Wallets
  useEffect(() => {
    if (authorized) {
      dispatch(loadUserWallets());
      FlexAPI.unsubscribe(USER_WALLETS_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeUserWallets(authorized?.userId, authorized?.flexClient, USER_WALLETS_SUBSCRIPTION_ID, (updatedWallets: any[]) => {
          console.log('UPDATE USER WALLETS => ', updatedWallets);
          dispatch(updateUserWallets(updatedWallets.map(w => ({
            address: w.address,
            ticker: w.token.ticker,
            totalBalance: parseFloat(w.totalBalance),
            decimals: w.token.decimals,
            availableBalance: parseFloat(w.availableBalance),
            nativeCurrencyBalance: parseFloat(w.nativeCurrencyBalance),
            balanceInOrders: parseFloat(w.balanceInOrders),
          }))));
        });
      });
    }

    return () => {
      FlexAPI.unsubscribe(USER_WALLETS_SUBSCRIPTION_ID);
    }
  }, [authorized, dispatch]);



  // Subscribe User Open Orders
  useEffect(() => {
    if (authorized) {
      dispatch(loadUserOpenOrders());
      FlexAPI.unsubscribe(USER_ORDERS_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeUserOrders(authorized?.userId, USER_ORDERS_SUBSCRIPTION_ID, (userOrders: any[]) => {
          console.log('UPDATE USER ORDERS => ', userOrders);
          //to UserOrder
          dispatch(updateUserOpenOrders(userOrders.map(order => ({
            ticker: order.pair.ticker,
            priceScale: parseInt(order.pair.priceScale),
            minAmount: parseFloat(order.pair.minAmount),
            minMove: parseFloat(order.pair.minMove),
            pairAddress: order.pair.address,
            side: order.side,
            price: parseFloat(order.price),
            amount: parseFloat(order.amountLeft),
            priceNum: order.priceNum,
            orderId: order.orderId,
            finishTime: order.finishTime,
          }))));
        });
      });
    }
    return () => {
      FlexAPI.unsubscribe(USER_ORDERS_SUBSCRIPTION_ID);
    }
  }, [authorized, dispatch]);

  // Subscribe User Trades
  useEffect(() => {
    if (authorized) {
      dispatch(loadUserTrades());
      FlexAPI.unsubscribe(USER_TRADES_SUBSCRIPTION_ID).then(() => {
        FlexAPI.subscribeUserTrades(authorized?.userId, USER_TRADES_SUBSCRIPTION_ID, (trades: any[]) => {
          console.log('UPDATE USER TRADES => ', trades);
          dispatch(updateUserTrades(trades.map((trade: any) => ({
            id: trade.id,
            ticker: trade.pair.ticker,
            priceScale: parseInt(trade.pair.priceScale),
            minAmount: parseFloat(trade.pair.minAmount),
            minMove: parseFloat(trade.pair.minMove),
            time: trade.time,
            side: trade.side,
            price: parseFloat(trade.price),
            amount: parseFloat(trade.amount),
            fees: parseFloat(trade.fees),
            feesToken: trade.feesToken ? trade.feesToken.ticker : '',
            liquidity: trade.liquidity,
          }))));
        });
      });
    }
    return () => {
      FlexAPI.unsubscribe(USER_TRADES_SUBSCRIPTION_ID);
    }
  }, [authorized, dispatch]);

  useEffect(() => {
    if (selectedPair) {
      //reset stats
      dispatch(setStats(null));

      FlexAPI.unsubscribe(STATS_SUBSCRIPTION_ID).then(() => {
        dispatch(loadStats());
        console.log('subscribeBars, selectedPair =>',selectedPair);
        FlexAPI.subscribeBars(
          FlexAPI.getSymbolName({ticker: selectedPair?.ticker, address: selectedPair?.address}),
          24*60, /*24h in minutes*/
          (newBar: any) => {
            //console.log(`${selectedPair?.ticker} newBar =>`, newBar);
            dispatch(setStats({
              h24high: newBar.high,
              h24low: newBar.low,
              h24open: newBar.open,
              h24volume: newBar.volume,
              price: newBar.close,
            }));
          },
          STATS_SUBSCRIPTION_ID
        );
      });
    }
    return () => {
      FlexAPI.unsubscribe(STATS_SUBSCRIPTION_ID);
    }
  }, [selectedPair]);

  return useMemo(
    () => ({
      selectedPair,
      selectedOrderBookRow,

      pairInfo,
      authorized,

      exchangeMode,
      setExchangeMode: setExchangeMode_,

      chartMode,
      setChartMode,

      userOpenOrders,
      userWallets,
      userTrades,
      userGas,

      trade,
      tradeStatus,
      setTradeStatus,
      tradeLoading,
      cancelOrder,
    }),
    [
      selectedPair,
      selectedOrderBookRow,
      exchangeMode,
      setExchangeMode_,
      authorized,
      pairInfo,
      userTrades,
      chartMode,
      setChartMode,
      userOpenOrders,
      tradeStatus,
      userWallets,
      userGas,
    ],
  );
};
