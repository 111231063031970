import {
  SET_PAIRS,
  LOAD_PAIRS,

  SET_ORDERBOOK,
  LOAD_ORDERBOOK,

  SET_TRADES,
  LOAD_TRADES,
  UPDATE_TRADES,

  SET_STATS,
  LOAD_STATS,

  SET_USER_WALLETS,
  UPDATE_USER_WALLETS,
  LOAD_USER_WALLETS,

  SET_USER_TRADES,
  LOAD_USER_TRADES,
  UPDATE_USER_TRADES,

  SET_USER_FUNDS,
  LOAD_USER_FUNDS,

  SET_USER_OPEN_ORDERS,
  LOAD_USER_OPEN_ORDERS,
  UPDATE_USER_OPEN_ORDERS,

  SET_UPDATE_LINK,
} from "./constants";

import {
  TradingPairInfo,
  TradeData,
  OrderBookRow,
  TradeStatistic,
  UserFund,
  UserTradeData,
  UserWallet,
} from "../../interfaces";

export const setUpdateLink = (updateLink: any) =>
  ({
    type: SET_UPDATE_LINK,
    payload: updateLink,
  } as const);

export const loadPairs = () =>
  ({
    type: LOAD_PAIRS,
    payload: [] as TradingPairInfo<number>[]
  } as const);

export const setPairs = (value: TradingPairInfo<number>[]) =>
  ({
    type: SET_PAIRS,
    payload: value,
  } as const);

export const loadOrderBook = () =>
  ({
    type: LOAD_ORDERBOOK,
    payload: null,
  } as const);

export const setOrderBook = (value: OrderBookRow[]) =>
  ({
    type: SET_ORDERBOOK,
    payload: value,
  } as const);

export const setTrades = (value: TradeData[]) =>
({
  type: SET_TRADES,
  payload: value,
} as const);

export const updateTrades = (value: TradeData[]) =>
({
  type: UPDATE_TRADES,
  payload: value,
} as const);

export const loadTrades = () =>
({
  type: LOAD_TRADES,
  payload: null,
} as const);


export const setStats = (value: TradeStatistic) =>
({
  type: SET_STATS,
  payload: value,
} as const);

export const loadStats = () =>
({
  type: LOAD_STATS,
  payload: null,
} as const);


export const setUserWallets = (value: any) =>
({
  type: SET_USER_WALLETS,
  payload: value,
} as const);

export const updateUserWallets = (value: UserWallet[]) =>
({
  type: UPDATE_USER_WALLETS,
  payload: value,
} as const);


export const loadUserWallets = () =>
({
  type: LOAD_USER_WALLETS,
  payload: null,
} as const);


export const setUserTrades = (value: UserTradeData[]) =>
({
  type: SET_USER_TRADES,
  payload: value,
} as const);

export const updateUserTrades = (value: UserTradeData[]) =>
({
  type: UPDATE_USER_TRADES,
  payload: value,
} as const);

export const loadUserTrades = () =>
({
  type: LOAD_USER_TRADES,
  payload: null,
} as const);


export const setUserFunds = (value: UserFund[]) =>
({
  type: SET_USER_FUNDS,
  payload: value,
} as const);

export const loadUserFunds = () =>
({
  type: LOAD_USER_FUNDS,
  payload: null,
} as const);


export const setUserOpenOrders = (value: any) =>
({
  type: SET_USER_OPEN_ORDERS,
  payload: value,
} as const);

export const loadUserOpenOrders = () =>
({
  type: LOAD_USER_OPEN_ORDERS,
  payload: null,
} as const);

export const updateUserOpenOrders = (value: any) =>
({
  type: UPDATE_USER_OPEN_ORDERS,
  payload: value,
} as const);
