import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
// import cn from "classnames";
// import { Provider } from "react-redux";

import Router from "./router";
// import "bootstrap/scss/bootstrap.scss";
// import "bootstrap/scss/_accordion.scss";

// import "../assets/styles/custom.scss";
import "../assets/styles/index.scss";
// import { store } from "../../redux/store/configure-store";
// import "../../assets/style/common.sass";
// import "../../assets/style/font.sass";
// import "../../assets/style/v-app.sass";
// import "../../assets/style/media.sass";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { store } from "../redux/store/configure-store";
import { ENDPOINT } from "../client/client";
import GA from "../ga";

import ChartsFlex from '../flex_charts/api/flexAPI';
import { Provider } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';

declare global {
    interface Window {
        updateInfo: any;
    }
}

const App = () => {
  const [isHidden, setIsHidden] = useState(true);

  // const removePreSpinner = () => {
  //     const spinnerDiv = document.getElementById('spinner-container');
  //     if (spinnerDiv) {
  //         spinnerDiv.remove();
  //     }
  // };

  // const refresh = () => {
  //     setIsHidden(true);
  //     setImmediate(() => setIsHidden(false));
  // };

  useEffect(() => {
      // langListenerDisposer = reaction(
      //     () => localizationService.language,
      //     refresh,
      //     { name: 'Language switch reaction' },
      // );

      // themeListenerDisposer = reaction(
      //     () => themesService.isDarkTheme,
      //     () => {
      //         refresh();
      //         pageHistory.pop();
      //     },
      //     { name: 'Theme toggle reaction' },
      // );

      const themeSwitch: any = document.getElementById('theme-switch');
      if (themeSwitch) {
        const lightTheme = localStorage.getItem('flexThemeLight');
        themeSwitch.checked = !lightTheme;
      };

      ChartsFlex.setEndpoint(ENDPOINT);
      GA.sendStarted();

      (async () => {
          // await Configs.setup();
          setIsHidden(false);
      })();
  }, []);

  if (isHidden) {
      return null;
  }

  return (
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
};



export default App;
