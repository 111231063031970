// @ts-nocheck
import callback from "./abi";

import { Debots } from "./client";
import DebotClient from "./client";

const browser = import("debot-browser");

export default class Trade {
  //trade manifest
  private static buildTradeManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('trade')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.tradeabi}
    }`);
  };

  //trade debot methods
  public static async marketOrder(
    extensionPubkey: string,
    flexClient: string,
    userId: string,
    tradingPair: address,
    sell: boolean,
    amount: string,
   ): Promise<TradeStatus> {
     const debotClient = await DebotClient.get();
     console.log(`Calling trade for ${tradingPair}...\n`);
     const manifest = Trade.buildTradeManifest(
       "marketOrder",
       `{
         "pubkey": "0x${extensionPubkey}",
         "flexClient": "${flexClient}",
         "userId": "${userId}",
         "tradingPair": "${tradingPair}", "sell": "${sell}", "amount": "${amount}"
       }`)
     console.log(manifest)
     const tradeResult = await (await browser).run_browser((await debotClient.tradeBrowserHandle), manifest);
     Trade.autoTopup(extensionPubkey, flexClient, userId, tradingPair); //call after every trade
     return tradeResult;
   }

   public static async limitOrder(
     extensionPubkey: string,
     flexClient: string,
     userId: string,
     tradingPair: address,
     sell: boolean,
     ioc: boolean,
     post: boolean,
     amount: string,
     priceNum: string
   ): Promise<TradeStatus> {
     const debotClient = await DebotClient.get();
     console.log(`Calling trade for ${tradingPair}...\n`);
     const manifest = Trade.buildTradeManifest(
       "limitOrder",
       `{
         "pubkey": "0x${extensionPubkey}",
         "flexClient": "${flexClient}",
         "userId": "${userId}",
         "tradingPair": "${tradingPair}", "sell": "${sell}", "ioc": "${ioc}", "post": "${post}", "amount": "${amount}", "priceNum": "${priceNum}"
       }`)
     console.log(manifest)
     const tradeResult = await (await browser).run_browser((await debotClient.tradeBrowserHandle), manifest);
     Trade.autoTopup(extensionPubkey, flexClient, userId, tradingPair); //call after every trade
     return tradeResult;
   }

   public static async autoTopup(
     extensionPubkey: string,
     flexClient: string,
     userId: string,
     tradingPair: address,
   ): Promise<TradeStatus> {
     const debotClient = await DebotClient.get();
     console.log(`Calling trade for ${tradingPair}...\n`);
     const manifest = Trade.buildTradeManifest(
       "autoTopup",
       `{"pubkey": "0x${extensionPubkey}", "flexClient": "${flexClient}", "userId": "${userId}", "tradingPair": "${tradingPair}"}`)
     console.log(manifest)
     return await (await browser).run_browser((await debotClient.tradeBrowserHandle), manifest);
   }

   public static async cancelOrder(
     extensionPubkey: string,
     flexClient: string,
     userId: string,
     tradingPair: address,
     sell: boolean,
     price: string,
     orderId: string,
   ): Promise<TradeStatus> {
     const debotClient = await DebotClient.get();
     console.log(`Calling cancelOrder for ${tradingPair}...\n`);
     const manifest = Trade.buildTradeManifest("cancelOrder", `{"flexClient": "${flexClient}", "userId": "${userId}", "tradingPair": "${tradingPair}", "sell": "${sell}", "priceNum": "${price}", "orderId": "${orderId}"}`)
     console.log(manifest)
     const tradeResult = await (await browser).run_browser((await debotClient.tradeBrowserHandle), manifest);
     Trade.autoTopup(extensionPubkey, flexClient, userId, tradingPair); //call after every cancelOrder
     return tradeResult;
   }

   /*async getSBox(): Promise<any> {
     console.log(`Calling getSBox`);
     const manifest = this.buildTradeManifest("getSBox", `{}`)
     return await (await browser).run_browser((await this.tradeBrowserHandle), manifest);
   }*/
}
