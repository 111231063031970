import { Table } from "react-bootstrap";
import styles from "./Trades.module.scss";
import classnames from "classnames/bind";
import { Panel, Skeleton } from "../../components";
import { useTrades } from "../../controllers";

import { TradingPairInfo } from "../../interfaces";

import {getDecimalsCount, formatTime, formatNumber, isWithinLast24H} from "../utils";

const cn = classnames.bind(styles);

export interface ITradesProps {
  selectedPair?: TradingPairInfo<number> | undefined;
}

const TradesRow = ({
  color,
  price,
  volume,
  time
}: {
  color: string,
  price: string,
  volume: string,
  time: number
}) => {
  return (<tr>
    <td className={cn(color)}>{price}</td>
    <td className="right">{volume}</td>
    <td className="right faded">{formatTime(time)}</td>
  </tr>);
}

export const Trades = ({selectedPair}: ITradesProps) => {
  const { trades, promiseState } = useTrades(selectedPair);

  return (
  <Panel
    header={"Recent trades"}
  >
  <div className={cn("scrollable-table-container")}>
  <Table>
      <thead>
        <tr>
          <th className="paragraph paragraph-footnote">{selectedPair ? <>Price, {selectedPair.ticker.split("/")[1]}</> : <Skeleton wide="half" />}</th>
          <th className="paragraph paragraph-footnote right">{selectedPair ? <>Amount, {selectedPair.ticker.split("/")[0]}</> : <Skeleton wide="half" />}</th>
          <th className="paragraph paragraph-footnote right">{selectedPair ? <>Time</> : <Skeleton wide="triplet" />}</th>
        </tr>
      </thead>
      {selectedPair && trades && trades.length && promiseState.isSuccess()
        ?
          <tbody className={cn("mono-narrow mono-narrow-note", "trades")}>
            {trades
              .filter(trade => isWithinLast24H(trade.time))
              .sort((a, b) => b.time - a.time)
              .slice(0, 50)
              .map((row, index) =>
                <TradesRow
                  key={index}
                  color={row.side.toLowerCase() === "buy" ? "green" : "red"}
                  price={formatNumber(row.price,   getDecimalsCount(selectedPair.minMove/selectedPair.priceScale))}
                  volume={formatNumber(row.volume, getDecimalsCount(selectedPair.minAmount))}
                  time={row.time}
                />
              )
            }
          </tbody>
        :
          <tbody className={cn("mono-narrow mono-narrow-note", "trades", "trades-loading")}>
            {Array(8).fill(null).map((el, index) => <tr key={index}>
                <td style={{width: "100%"}}><Skeleton variant="primary" /></td>
                <td className="right"><Skeleton wide="half" /></td>
                <td className="right"><Skeleton wide="triplet"/></td>
              </tr>
            )}
          </tbody>
      }
    </Table>
    </div>
    </Panel>
  );
}

export default Trades;
