import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../types";
import { getConfigByDomain } from "../client/client";

import ChartsFlex from '../flex_charts/api/flexAPI';
import { ElectronUtils } from '../client/ElectronUtils';

import {
  TradingPairInfo
 } from "../interfaces";

import {
  setPairs,
  loadPairs,
  setPair,
  setOrderBook,
  setTrades,
  setUpdateLink,
} from "../redux/actions";

export interface IUseHeader {
    pairs: TradingPairInfo<number>[],
    selectedPair: TradingPairInfo<number> | undefined,
    setSelectedPair: any,
    toggleFavouritePair: any,
    authorized: any,
    updateLink: any,
}

export const useHeader = (): IUseHeader => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { pairs: { data: pairs} } = useSelector((state: RootState) => state.flex);
  const { selectedPair, authorized } = useSelector((state: RootState) => state.dashboard);
  const { updateLink: { data: updateLink} } = useSelector((state: RootState) => state.flex);
  //console.log('selector updateLink = ', updateLink);

  const setSelectedPair = (value: TradingPairInfo<number>):void => {
    if(pairs) {
      const pair: TradingPairInfo<number> | undefined = pairs.find(
        (pair: TradingPairInfo<number>) => {
          return pair ? pair.ticker === value.ticker : undefined
        }
      )
      if(pair && pair !== selectedPair) {
        dispatch(setPair(pair));
        dispatch(setOrderBook([]));
        dispatch(setTrades([]))
        // dispatch(setCandlestickChart({}))
        // dispatch(setPriceLinearChart({}))
        // dispatch(setLinearChart({}))
      }
    }
  }

  const toggleFavouritePair = (value: TradingPairInfo<string>):void => {
    if (pairs) {
    }
  }

  useEffect(() => {
    ElectronUtils.requestDownloadUrl().then(downloadUrl => {
      dispatch(setUpdateLink(downloadUrl));
    })
    .catch(()=>{

    })
    /*const listener = (message: any) => {
      if (
        !message.data ||
        !message.data.fromElectron ||
        message.data.fromElectron.type !== 'get-download-url'
      ) return;
      dispatch(setUpdateLink(message.data.fromElectron.payload));
    };
    window.addEventListener('message', listener);

    return ()=>{
      window.removeEventListener('message', listener);
    }*/
  });

  useEffect(() => {
    dispatch(loadPairs());
    ChartsFlex.flexPairs()
    .then((value) => {
      //console.log('flex pairs: ', value);
      const pairsDisabled = getConfigByDomain().pairsDisabled || [];

      const tradingPairs = value.filter((pair: any) => !pairsDisabled.includes(`${pair.major.ticker}/${pair.minor.ticker}`)).map((pair: any) => ({
        ...pair,
        major: pair.major.ticker,
        minor: pair.minor.ticker,
        majorDecimals: parseFloat(pair.major.decimals),
        minorDecimals: parseFloat(pair.minor.decimals),
        open: 0,//parseFloat(pair.open),
        price: 0,//parseFloat(pair.price),
        priceScale: parseFloat(pair.priceScale),
        minAmout: parseFloat(pair.minAmount),
      }));

      dispatch(setPairs(tradingPairs));

      tradingPairs.forEach((pair: any) => {
        ChartsFlex.unsubscribe(`${pair.ticker}-STATS_SUBSCRIPTION`).then(() => {
          ChartsFlex.subscribeBars(
            ChartsFlex.getSymbolName({ticker: pair.ticker, address: pair.address}),
            24*60, /*24h in minutes*/
            (newBar: any) => {
              //console.log(`${selectedPair?.ticker} newBar =>`, newBar);
              pair.open = newBar.open;
              pair.price = newBar.close;
              dispatch(setPairs(tradingPairs));
            },
            `${pair.ticker}-STATS_SUBSCRIPTION`
          );
        });
      });

    });
  }, []);

  useEffect(() => {
    if (pairs && pairs.length) {
      dispatch(setPair(pairs.find((a) => a.ticker === getConfigByDomain().defaultPairTicker) || pairs[pairs.length - 1]));
    }
  }, [pairs, dispatch]);

  return useMemo(
    () => ({
        pairs: pairs,
        authorized,
        selectedPair: selectedPair,
        setSelectedPair,
        toggleFavouritePair,
        updateLink
    }),
    [pairs, authorized, selectedPair, toggleFavouritePair, updateLink],
  );
};
