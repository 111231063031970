import { combineReducers } from "redux";

import {
  dashboardReducer,
  flexReducer,
} from "./index";

export const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  flex: flexReducer
});
