import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../types";

import {
  TradeData,
  OrderBookRow,
  TradingPairInfo,
  ChartsFlexOrder,
  ChartsFlexOrderbook
 } from "../interfaces";

import {
  setOrderBook,
  loadOrderBook,
  selectOrderBookRow
} from "../redux/actions";

import ChartsFlex from '../flex_charts/api/flexAPI';

import { PromiseState } from "../utils/store/PromiseUtils";

import { ORDERBOOK_SUBSCRIPTION_ID } from './subscriptions';

export interface IUseOrderbook {
  orderbook: Array<{price: number, buyVolume: number, sellVolume: number, priceScale: number, minAmount: number}>,
  orderbookMax?: number,
  promiseState: PromiseState,
  trades: TradeData[],
  tradesPromiseState: PromiseState,
  selectOrder?: any
}

export const useOrderbook = (selectedPair: TradingPairInfo<number> | undefined): IUseOrderbook => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { orderbook: { data: orderbook, promiseState }, trades: {data: trades, promiseState: tradesPromiseState} } = useSelector((state: RootState) => state.flex);

  const __orderbook = (orderbook as OrderBookRow[]).map(row => ({
    price: parseFloat(row.price),
    priceScale: row.priceScale,
    minAmount: row.minAmount,
    buyVolume: parseFloat(row.buyVolume),
    sellVolume: parseFloat(row.sellVolume)
  }));

  const countSellDeals = __orderbook.filter((row) => row.sellVolume).length;

  /*if (countSellDeals < 7)
    __orderbook.splice(14)
  else {
    __orderbook.splice(0, countSellDeals - 7);
    __orderbook.splice(14);
  }*/

  const __orderbookMax = __orderbook.reduce((a, b) => {
    const volume = b.price * (b.buyVolume || b.sellVolume);
    return (a > volume
      ? a
      : volume
    );
  }, 0);

  const selectOrder = (value:OrderBookRow) => {
    dispatch(selectOrderBookRow(value));
  }

  useEffect(() => {
    if (selectedPair) {
       ChartsFlex.unsubscribe(ORDERBOOK_SUBSCRIPTION_ID).then(() => {
         dispatch(loadOrderBook());
         ChartsFlex.subscribeOrderbook(selectedPair?.address, ORDERBOOK_SUBSCRIPTION_ID, (newOrderBook: ChartsFlexOrderbook, pairInfo: any) => {
           //console.log(`${selectedPair?.ticker} newOrderBook non-converted =>`, newOrderBook);
           //convert to OrderBookRow[]
           const orderBookMap = new Map();
           newOrderBook.bids.forEach((order: ChartsFlexOrder) => {
             const price = orderBookMap.get(order.price);
             if (price) price.buyVolume += order.amount;
             else orderBookMap.set(order.price, {price: order.price, buyVolume: order.amount, sellVolume: 0, minAmount: pairInfo?.minAmount || 1, priceScale: pairInfo?.priceScale || 2});
           });
           newOrderBook.asks.forEach((order: ChartsFlexOrder) => {
             const price = orderBookMap.get(order.price);
             if (price) price.sellVolume += order.amount;
             else orderBookMap.set(order.price, {price: order.price, sellVolume: order.amount, buyVolume: 0, minAmount: pairInfo?.minAmount || 1, priceScale: pairInfo?.priceScale || 2});
           });
           const orderBookRows = Array.from(orderBookMap.values()).sort((a,b)=>b.price - a.price);
           //console.log(`${selectedPair?.ticker} newOrderBook converted =>`, orderBookRows);
           dispatch(setOrderBook(orderBookRows));
         });
       });
    }

    return () => {
      ChartsFlex.unsubscribe(ORDERBOOK_SUBSCRIPTION_ID);
    }
  }, [selectedPair, dispatch]);

  /*for (let i = 1; i <= 10; ++i) {
    __orderbook.push({
      buyVolume: 0,
      minAmount: 0.1,
      price: 0.1 + i,
      priceScale: 1000000000,
      sellVolume: 2 + i,
    });
    __orderbook.push({
      buyVolume: 2 + i,
      sellVolume: 0,
      minAmount: 0.1,
      price: 0.1 + i,
      priceScale: 1000000000,
    });
  }*/

  return useMemo(
    () => ({
      promiseState: promiseState,
      tradesPromiseState: tradesPromiseState,
      orderbook: __orderbook,
      trades: (trades as TradeData[]).sort((a, b) => b.time - a.time).slice(0, 2),
      orderbookMax: __orderbookMax,
      selectOrder
    }),
    [selectedPair, orderbook, trades, selectOrder],
  );
};
