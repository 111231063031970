import React, { useState, useEffect, useRef, ReactNode } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './Picker.module.scss';
import { useFormikContext, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { Icon } from "..";
import useOutsideClick from "../../utils/outsideClickCallback";
import { FormGroupProps } from "../../interfaces";

export interface OptionProps {
  label: string,
  title: string,
  value: string,
  disabled?: boolean,
  default?: boolean,
  hidden?: boolean,
}

export interface PickerProps {
  options: OptionProps[],
  currency?: any,
  amount?: any,
  callback?: (props: any) => void,
  inputGroupStyle?: any,
}

const InputFC = ({ field, form, ...props }: FieldProps) => {
  return <input {...field} {...props} />;
};

export const Picker = <FormValues, >({
  label,
  disabled,
  options,
  fieldCurrency,
  fieldAmount,
  className,
  callback,
  inputGroupStyle
} : FormGroupProps & PickerProps & {
  fieldCurrency: keyof FormValues,
  fieldAmount: keyof FormValues
}) => {
  const {
    values,
    errors,
    setFieldValue,
  } : {
    values: FormValues,
    errors: FormikErrors<FormValues>,
    handleChange: any,
    handleBlur: any,
    touched: FormikTouched<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  const currency = values[fieldCurrency];
  const amount = values[fieldAmount];

  const [active, setActive] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<OptionProps>(options[0]);
  const [dropdownOptions, setDropdownOptions] = useState<ReactNode>(<></>);

  const refPicker = useRef<HTMLDivElement>(null);
  const refButton = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOption(options.find((option, index) => option.value === String(currency)) || options[0]);
  }, [currency, options]);

  const handleOptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
    const selected = options.find((option, index) => option.value === event.currentTarget.getAttribute("data-value") as string) || options[0];
    setSelectedOption(selected);
    callback && callback(selected);

    setFieldValue(
      fieldCurrency as string,
      event.currentTarget.getAttribute("data-value") as string || ''
    );
    handleDropdownClick(false);
  };

  useEffect(() => {
    setDropdownOptions([
     options.map((option, index) =>
      <div
        key={index}
        data-value={option.value}
        className={`${styles.option} ${option.disabled ? styles['option-disabled'] : ''}  ${selectedOption.value === option.value ? styles['option-selected'] : ''}`}
        onClick={option.disabled ? undefined : handleOptionClick}>
          {option.value && <div className={styles['option-icon']}><Icon icon={option.value}/></div>}
          <div className={styles['option-label']}>{option.label}</div>
          <div className={styles['option-title']}>{option.title}</div>
      </div>
  )]);
  }, [currency, selectedOption]);

  const handleDropdownClick = (value: boolean) => setActive(value);

  useOutsideClick(refPicker, () => handleDropdownClick(false));

  const hasLabel = !errors[fieldCurrency] && !errors[fieldAmount] && !!currency && !!amount && !active;
  const hasError = errors[fieldCurrency] || errors[fieldAmount];

  return <>

  <div ref={refPicker} className={`${styles.picker} ${disabled && styles['picker-disabled']} ${className}`}>
    {false && <label className={`${styles.label} ${hasLabel ? styles['label-active'] : ''} ${hasError ? styles['label-error'] : ''}`}>
      {hasLabel && <>{label}</>}
      {hasError && <div className="invalid-feedback d-block">
        <>{errors[fieldCurrency]}{errors[fieldAmount]}</>
      </div>}
    </label>
    }
    <div className={`form-group ${styles.group}`} style={inputGroupStyle}>
      <div ref={refButton} onClick={() => handleDropdownClick(true)} className={styles['dropdown-button']}>

        <div className={styles['dropdown-button-body']}>
          {`${selectedOption.label} ${selectedOption.title}`}
          <span>
            {selectedOption.value}
          </span>
        </div>
        <Icon className={styles['dropdown-button-chevron']} icon={"chevron-down"} />
      </div>
    </div>
    <Field as="select"
      hidden={true}
      name={fieldCurrency}
      className={styles.select}
      placeholder={label}>
      {
        options.map((option, index) => <option key={index} value={option.value}>{option.title}</option>)
      }
    </Field>
    <div className={`${styles['dropdown-menu']} ${active ? styles['dropdown-menu-active'] : null}`}>
      <div className={`${styles['dropdown-menu-header']}`} onClick={() => handleDropdownClick(false)}>
        <Icon className={styles['dropdown-button-chevron']} icon={"chevron-up"} />
      </div>
      <div className={`${styles['dropdown-menu-body']}` }>
        {dropdownOptions}
      </div>
    </div>
  </div>
  </>;
};
