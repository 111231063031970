import { FunctionComponent } from "react";
import { Toast as ToastBootstrap, ToastContainer } from "react-bootstrap";
import styles from "./Toast.module.scss";
import classnames from "classnames/bind";
import { Icon } from "..";

const cn = classnames.bind(styles);

export const Toast = ({
  className = "",
  children,
  header,
  show,
  onClose
}: {
  className?: string,
  icon?: React.ReactNode,
  children?: React.ReactNode,
  header?: React.ReactNode,
  show: boolean,
  onClose: any,
}) => {
  return <ToastContainer className={cn("toast-container", "p-4")} position={'top-end'}>
    <ToastBootstrap show={show} onClose={onClose} className={cn(className, "toast")}>
    <ToastBootstrap.Header className={cn("toast-header")} closeButton={false}>
      {header}<button onClick={() => onClose()}><Icon icon="close"/></button>
    </ToastBootstrap.Header>
    <ToastBootstrap.Body className={cn("toast-body")}>{children}</ToastBootstrap.Body>
  </ToastBootstrap>
  </ToastContainer>
};

export default Toast;
