import { ActionTypes } from "../../types/action-types";
import {
  SET_AUTH,
  SELECT_PAIR,
  SELECT_ORDERBOOK_ROW,
  SET_EXCHANGE_MODE
} from "../actions/constants";

import {
  TradingPairInfo,
  OrderBookRow,
  Authorized,
  exchangeMode
} from "../../interfaces";

interface IInitialState {
  authorized: Authorized | undefined,
  selectedPair: TradingPairInfo<number> | undefined,
  selectedOrderBookRow: OrderBookRow | undefined,
  exchangeMode: exchangeMode,
}

const initialState: IInitialState = {
  authorized: undefined,
  selectedPair: undefined,
  selectedOrderBookRow: undefined,
  exchangeMode: 'buy'
};

export const dashboardReducer = (
  state: IInitialState = initialState,
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_AUTH:
      return { ...state, authorized: payload as Authorized};
    case SELECT_PAIR:
      return { ...state, selectedPair: payload as TradingPairInfo<number>};
    case SELECT_ORDERBOOK_ROW:
      return { ...state, selectedOrderBookRow: payload as OrderBookRow};
    case SET_EXCHANGE_MODE:
      return { ...state, exchangeMode: payload as exchangeMode};
    default:
      return state;
  }
};
