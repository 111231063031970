import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../types";

import {
  TradingPairInfo,
  TradeData,
 } from "../interfaces";

import {
  loadTrades,
  updateTrades,
} from "../redux/actions";

import ChartsFlex from '../flex_charts/api/flexAPI';

import { PromiseState } from "../utils/store/PromiseUtils";

import { RECENT_TRADES_SUBSCRIPTION_ID } from './subscriptions';

export interface IUseTrades {
  trades: TradeData[],
  promiseState: PromiseState
}

export const useTrades = (selectedPair: TradingPairInfo<number> | undefined): IUseTrades => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { trades: {data: trades, promiseState} } = useSelector((state: RootState) => state.flex);

  useEffect(() => {
    if (selectedPair) {
      //console.log(`${selectedPair?.ticker} trades before subscribe =>`, trades);
       ChartsFlex.unsubscribe(RECENT_TRADES_SUBSCRIPTION_ID).then(() => {
         dispatch(loadTrades());
         ChartsFlex.subscribeRecentTrades(selectedPair?.address, RECENT_TRADES_SUBSCRIPTION_ID, (recentTrades: TradeData[], pairInfo: any) => {
           //console.log(`UPDATE RECENT TRADES =>`, recentTrades);
           dispatch(updateTrades(recentTrades.map(trade => ({...trade, minAmount: pairInfo?.minAmount || 1, priceScale: pairInfo?.priceScale || 2}))));
         });
       });
    }

    return () => {
      ChartsFlex.unsubscribe(RECENT_TRADES_SUBSCRIPTION_ID);
    }
  }, [selectedPair, dispatch]);

  return useMemo(
    () => ({
      trades: trades.sort((a, b) => b.time -   a.time),
      promiseState: promiseState,
      orderbookMax: undefined,
      selectOrder: undefined
    }),
    [selectedPair, trades],
  );
};
