// @ts-nocheck
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    hasSurfKeeperProvider
} from '@eversurf/surfkeeper-provider';

import styles from "./AuthWeb.module.scss";
import classnames from "classnames/bind";

import { Modal, Button } from "react-bootstrap";

import {getDecimalsCount, shiftedBy, formatNumber} from "../utils";
import { getConfigByDomain } from "../../client/client";
import { Icon } from "../../components";

const cn = classnames.bind(styles);
const SURF_EXTENSION_URL = "https://chrome.google.com/webstore/detail/ever-surf-keeper/dfldeefnhahdhaolbkhehhlcgagefgff";

export const Onboarding = (props: any) => {
  const {handleConnectSurf, show, onOk} = props;
  const [hasSurf, setHasSurf] = useState(null);

  useEffect(() => {
    hasSurfKeeperProvider()
    .then(res => setHasSurf(res));
  }, [props.show]);

  return (
    <Modal
      id="onboarding-modal"
      show={show}
      onHide={()=>onOk()}
      keyboard={false}
      dialogClassName={cn("flex-modal")}
      contentClassName="border-0 p-0"
      centered
    >
      <div
        className="flex-modal d-flex flex-column p-3 rounded text-white"
        style={{minHeight: 300, backgroundColor: '#161A1E'}}
      >
        <div className="fs-4 fw-bold">
          Connect Wallet
        </div>
        <div>
          Please select a wallet to sign in to this dApp
        </div>

        {hasSurf !== null &&
        <div
          className="d-flex mt-4 flex-column align-items-center p-3 rounded"
          style={{border: '1px solid rgb(66, 89, 207)'}}
          role="button"
          onClick={() => {
            onOk();
            if (hasSurf) {
              handleConnectSurf();
            } else {
              window.open(
                SURF_EXTENSION_URL,
                "_blank"
              );
            }
          }}
        >
          <div>Ever Surf Keeper{hasSurf ? null : <Icon icon={"arrow-up-right"} className="ms-2"/>}</div>
          <small className="text-muted">Browser Extension</small>
        </div>
        }

        <Button
          className={cn("mt-auto w-100 btn-action")}
          style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
          onClick={onOk}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
