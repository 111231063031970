import { useEffect, ReactNode, InputHTMLAttributes } from "react";
//import React, { useState, useEffect, useRef, ReactNode, InputHTMLAttributes } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './Input_.module.scss';
// import { FieldProps } from 'formik';
// import { FormikContextType, useFormikContext, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { Icon } from "..";
import { InputProps } from ".";
// /import useOutsideClick from "../../utils/outsideClickCallback";
import { FormGroupProps } from "../../interfaces";

// export interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
//   icon?: string,
//   units?: ReactNode,
//   action?: ReactNode
// }

// const InputFC = ({ field, form, ...props }: FieldProps) => {
//   return <input {...field} {...props} />;
// };

export const InputDeprecated = <FormValues, >({
  label,
  value,
  placeholder,
  icon,
  units,
  disabled,
  name,
  className,
  action,
  ...props
} : FormGroupProps & InputProps & {
  name: keyof FormValues
}) => {
  
  const errors:{[key: string]: any} = [];
  // const {
  //   values,
  //   errors,
  //   touched,
  //   setFieldValue,
  // } : {
  //   values: FormValues,
  //   errors: FormikErrors<FormValues>,
  //   handleChange: any,
  //   handleBlur: any,
  //   touched: FormikTouched<FormValues>,
  //   setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  // } = useFormikContext<FormValues>();

  // const [active, setActive] = useState<boolean>(false);
  // // const [value, setValue] = useState<string>("");

  // const refIcon = useRef<HTMLDivElement>(null);

  useEffect(() => {
    
  }, []);

  const hasError = errors[name];

  return <>
    <div className={`${styles.input}${disabled ? ' ' + styles['input-disabled'] : ''}${icon ? ' ' + styles['input-icon'] : ''}${className ? ' ' + className : ''}`}>
      {label && <label className={`paragraph paragraph-footnote ${styles.label} ${hasError ? styles['label-error'] : ''}`}>
        <>{label}</>
        {hasError && <div className="invalid-feedback d-block">
          {errors[name]}
        </div>}
      </label>}
      <div className={`form-group ${styles.group}`}>
        {icon && <div className={`${styles.icon}`}>
          <Icon icon={icon} />
        </div>}
        <input
          name={name}
          value={value}
          disabled={disabled}
          className={`form-control ${styles['input-component']} ${value && styles['input-component-active']}`}
          //component={InputFC}
          placeholder={placeholder}
          {...props}
        />
        {units && <div className={`${styles.units}`}>
          {units}
        </div>}
        {action && <div className={`${styles.clear}`}>
          {action}
        </div>}
      </div>
    </div>
  </>;
};
