import { isNonEmptyArray, isNullOrEmpty, isDefinedNotNull } from './ObjectUtils';
import _ from 'lodash';
import { Enumify } from 'enumify';

export interface State<Type> {
  data: Type,
  promiseState: PromiseState,
  error: string | null
}

export class PromiseState extends Enumify {
  static INIT = new PromiseState();
  static SUCCESS = new PromiseState();
  static ERROR = new PromiseState();
  static LOADING = new PromiseState();
  static EMPTY = new PromiseState();
  static _ = this.closeEnum();

  isSuccess() {
    return this === PromiseState.SUCCESS;
  }
  isError() {
    return this === PromiseState.ERROR;
  }
  isEmpty() {
    return this === PromiseState.EMPTY;
  }
  isLoading() {
    return this === PromiseState.LOADING;
  }
  isInit() {
    return this === PromiseState.INIT;
  }
}

function setPromiseState<Type, StateType>(
  state: StateType,
  object: string,
  promiseState: PromiseState,
  data: Type,
  error: string = 'null'
): StateType {
  return Object.assign({}, state, {
    [object]: { data: data, promiseState: promiseState, error: error }
  });
}

export function setLoadingState<Type, StateType>(
  state: StateType,
  object: string,
  data: Type
) {
  if (data) {
    return setPromiseState(state, object, PromiseState.LOADING, data);
  } else {
    return Object.assign({}, state, {
      [object]: Object.assign({}, (state as any)[object], {
        promiseState: PromiseState.LOADING
      })
    });
  }
}

export function setSuccessState<Type, StateType>(state: StateType, object: string, data: Type):StateType {
  return setPromiseState(state, object, PromiseState.SUCCESS, data);
}

export function setFailureState<Type, StateType>(state: StateType, object: string, error: string, data?: Type):StateType {
  return setPromiseState(state, object, PromiseState.ERROR, data, error);
}

// Initial state itself to init field
export function getInitialState<Type>(data:Type):State<Type> {
  return { data: data, promiseState: PromiseState.INIT, error: null };
}

// Reset to initial state
export function setInitialState<Type, StateType>(state: StateType, object: string, data: Type, error?: string) {
  return setPromiseState(state, object, PromiseState.INIT, data, error);
}

export function getPromiseState<Type>(dataObject: State<Type>) {
  if (
    isDefinedNotNull(dataObject.data) &&
    (isNonEmptyArray(dataObject.data) || !isNullOrEmpty(dataObject.data))
  ) {
    if (dataObject.promiseState === PromiseState.LOADING) return PromiseState.LOADING;
    return PromiseState.SUCCESS;
  } else if (isDefinedNotNull(dataObject.promiseState) && dataObject.promiseState.isSuccess()) {
    return PromiseState.EMPTY;
  } else {
    return dataObject.promiseState;
  }
}

export function setPromiseResponse<Type, StateType>(state: StateType, object: string, response: any) {
  const {
    payload,
    payload: { data, status, isAxiosError }
  } = response;
  const objectState = _.omit(response, ['payload', 'type']);
  if (status !== 200 || isAxiosError || (isDefinedNotNull(data) && isDefinedNotNull(data.error))) {
    _.merge(objectState, {
      data: null,
      error: isDefinedNotNull(payload.response) && payload.response.data && payload.response.data.error,
      promiseState: PromiseState.ERROR
    });
  } else {
    _.merge(objectState, { data: data as Type, error: null, promiseState: PromiseState.SUCCESS });
  }
  return Object.assign({}, state, { [object]: objectState });
}
