import { Helmet } from 'react-helmet-async';

const MetaDecorator = ({
    title = "Ever Surf",
    description="A free, private and easy blockchain interface for smart people.", 
    keywords
  }: {title?: string, description?: string, keywords?: string}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default MetaDecorator;
