import { useEffect, InputHTMLAttributes } from "react";
// import React, { FunctionComponent, useState, useEffect, useRef, ReactNode, InputHTMLAttributes } from "react";
// import ReactSelect from 'react-select';
// import { CommonProps, GroupBase } from 'react-select';
// import OptionType from 'react-select';
import styles from './Switch.module.scss';
import { useFormikContext, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
// import { Icon } from "..";
// import useOutsideClick from "../../utils/outsideClickCallback";
import { FormGroupProps } from "../../interfaces";

export interface SwitchProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "value">{
  value: boolean | InputHTMLAttributes<HTMLInputElement>['value']
}

const InputFC = ({ field, form, ...props }: FieldProps) => {
  return <input {...field} type="checkbox" {...props} />;
};

export const Switch = <FormValues, >({
  label,
  value,
  disabled,
  name,
  className
} : FormGroupProps & SwitchProps & {
  name: keyof FormValues
}) => {
  const {
    // values,
    errors,
    // touched,
    // setFieldValue,
  } : {
    values: FormValues,
    errors: FormikErrors<FormValues>,
    handleChange: any,
    handleBlur: any,
    touched: FormikTouched<FormValues>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  } = useFormikContext<FormValues>();

  useEffect(() => {
    
  }, []);

  const hasError = errors[name];

  return <>
    <div className={`${styles.switch} ${disabled ? ' ' + styles['switch-disabled'] : ''}${className ? ' ' + className : ''}`}>
      <label className={`act act-note ${styles.label} ${hasError ? styles['label-error'] : ''}`} htmlFor={name}>
        {label}
      </label>
      <Field
        id={name}
        checked={value}
        name={name}
        disabled={disabled}
        component={InputFC}
      />
    </div>
  </>;
};
